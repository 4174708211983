/*
// Variables
// ------------------------------------------------ *//*==== Paths ====*//*==== Scafolding ====*//*==== Typography ====*//*===== Font Families  =====*//*==== Breakpoints ====*//*==== Grid System ====*//*
// Colors Abstract
// ------------------------------------------------ *//*
// Mixins
// ------------------------------------------------ *//*
// Functions
// ------------------------------------------------ *//*
// Font Families
// ------------------------------------------------ */.mfp-wrap .best-price-guarantee-popup {
  position: relative;
  padding: 6rem 4.9rem 3.2rem;
  margin: 0 auto;
  max-width: 69rem;
  height: 96vh;
  max-height: 56rem;
  color: #373737;
  background-color: #FFF;
  border-radius: .4rem;
}

.best-price-guarantee-popup-title {
  padding-bottom: 2.6rem;
  margin-bottom: 2rem;
  color: #00b8f4;
  text-transform: uppercase;
  font-family: "OpenSans-Semibold";
  font-size: 2.2rem;
  border-bottom: 0.1rem solid #ddd;
}

.best-price-guarantee-popup-title span {
  display: inline-block;
  text-transform: uppercase;
}

.best-price-guarantee-popup-title .price-value-1 {
  font-size: 4.6rem;
}

.best-price-guarantee-popup-title .price-value-2 {
  font-size: 3rem;
}

.best-price-guarantee-popup-subtitle {
  margin: 0 0 4rem;
  font-family: "OpenSans-Regular";
  font-size: 1.4rem;
  line-height: 2.2rem;
  color: #373737;
  text-align: left;
}

.best-price-guarantee-popup .popup-title {
  margin: 0 0 .5rem;
  font-family: "OpenSans-Semibold";
  font-size: 2.2rem;
  line-height: 1;
  color: #373737;
  text-align: left;
}

.best-price-guarantee-popup .popup-subtitle {
  margin: 0 0 2.4rem;
  font-family: "OpenSans-Regular";
  font-size: 1.4rem;
  line-height: 2.2rem;
  color: #373737;
  text-align: left;
}

.best-price-guarantee-popup .popup-subtitle a {
  display: inline-block;
  color: #00b8f4;
}

.best-price-guarantee-popup .popup-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.best-price-guarantee-popup .popup-content-text {
  width: 64%;
}

.best-price-guarantee-popup .popup-content figure {
  margin: 0;
}

.best-price-guarantee-popup .popup-content img {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 21rem;
}/* Corona Changes
main.main {
	padding: 0;
}*/.main-home {
  background-color: #ededed;
  overflow: hidden;
}

.main-home .mobileItems {
  display: block;
}

.main-home .focusRight {
  background-position: right !important;
}

.main-home .desktopItems {
  display: none;
}

.main-home .whole-slide {
  width: 100%;
}

.main-home .palmPatternBG {
  background-image: url("https:://cdn.sandals.com/beaches/v12/images/globals/leaves-pattern-dark-big.jpg");
}

.main-home img {
  height: auto;
  width: auto;
  max-width: 100%;
}

.main-home .whiteBg {
  background: #FFF;
}

.main-home .max-width {
  max-width: 192rem;
  margin: 0 auto;
}

.main-home #coronavirus-badge {
  pointer-events: none;
  position: relative;
  top: 90%;
}

.main-home #coronavirus-badge #corona-virus {
  position: absolute;
  top: -72vw;
  width: 12rem;
  right: 0;
  pointer-events: auto;
  z-index: 2;
}

.main-home .mobile-show {
  display: none !important;
}

.main-home .tablet-show {
  display: none !important;
}

.main-home .desktop-show {
  display: block !important;
}

.main-home #hero-slider {
  position: relative;
  height: 100vw;
}

.main-home #hero-slider .slide-wrap,.main-home #hero-slider .slick-list,.main-home #hero-slider .slick-track,.main-home #hero-slider .slick-slide,.main-home #hero-slider .slider-item,.main-home #hero-slider .slider-item-inner {
  height: 100%;
}

.main-home #hero-slider #slide-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.main-home #hero-slider .slide-wrap {
  position: relative;
}

.main-home #hero-slider .slide-wrap div:not(.container) {
  width: 100%;
  height: 100%;
}

.main-home #hero-slider .slider-item-inner {
  height: 100%;
  background-size: cover;
  background-position: center;
}

.main-home #hero-slider .slide-info {
  position: absolute;
  width: calc(100% - 3rem);
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  border-top: 0.1rem solid #fff7;
  align-items: center;
  margin: 0 1.5rem;
  z-index: 6;
}

.main-home #hero-slider #caption {
  color: #FFF;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.9rem;
  margin: 0;
}

.main-home #hero-slider .slide-counter {
  position: relative;
  height: 6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-home #hero-slider .slide-counter-inner {
  color: #FFF;
  display: block;
  text-align: center;
  width: auto;
  font-weight: 400;
  font-size: 2.6rem;
  line-height: 6rem;
  position: relative;
}

.main-home #hero-slider #playPause {
  cursor: pointer;
  position: relative;
  font-size: 2.5rem;
  top: 0.1rem;
}

.main-home #hero-slider #playPause:before {
  content: "";
  transition: color 100ms linear;
  font-family: "beaches-icons";
  color: #FFF;
}

.main-home #hero-slider #playPause.pause:before {
  content: "\e9d0";
}

.main-home #hero-slider #playPause.play:before {
  content: "\e9cf";
}

.main-home #hero-slider #playPause:hover:before {
  color: #00b8f4;
}

.main-home #hero-slider .slide-nav {
  width: 16rem;
}

.main-home #hero-slider .slide-nav [class^='icon'] {
  color: #FFF;
  font-size: 3rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  border-color: transparent;
  background-color: transparent;
}

.main-home #hero-slider .slide-nav [class^='icon']:before {
  font-family: "beaches-icons";
  transition: all 100ms linear;
  font-size: 27px;
  color: #FFF;
  opacity: 1;
}

.main-home #hero-slider .slide-nav [class^='icon']:hover:before {
  color: #00b8f4;
}

.main-home #hero-slider .slide-nav .slick-prev {
  left: 0;
  right: auto;
  position: relative;
  top: 0;
  transform: none;
}

.main-home #hero-slider .slide-nav .slick-prev:before {
  content: none;
}

.main-home #hero-slider .slide-nav .slick-prev i:before {
  content: "\e93d";
}

.main-home #hero-slider .slide-nav .slick-next {
  right: 0;
  left: auto;
  position: relative;
  top: 0;
  transform: none;
}

.main-home #hero-slider .slide-nav .slick-next:before {
  content: none;
}

.main-home #hero-slider .slide-nav .slick-next i:before {
  content: "\e93b";
}

.main-home #hero-slider .button-slider {
  background-color: transparent;
  border: none;
}

.main-home #hero-slider .slick-dots {
  display: block;
}

.main-home #hero-slider .slick-dots li {
  vertical-align: middle;
  transition: all 150ms ease-in;
  width: 0.8rem;
  height: 0.8rem;
  position: relative;
}

.main-home #hero-slider .slick-dots li:before {
  background-color: transparent;
}

.main-home #hero-slider .slick-dots li.slick-active button:before {
  background-color: #000;
  opacity: 1;
}

.main-home #hero-slider .slick-dots li button {
  width: 100%;
  height: 100%;
  cursor: default;
}

.main-home #hero-slider .slick-dots li button:before {
  background-color: #b7b7b7;
  content: '';
  width: 100%;
  border-radius: 50%;
  height: 100%;
  opacity: 1;
}

.main-home #hero-slider .slick-dots li.large {
  width: 0.8rem;
  height: 0.8rem;
}

.main-home #hero-slider .slick-dots li.semi {
  width: 0.5rem;
  height: 0.5rem;
  top: 0.05rem;
}

.main-home #hero-slider .slick-dots li.small {
  font-size: inherit;
  width: 0.3rem;
  top: 0.05rem;
  height: 0.3rem;
}

.main-home #hero-slider .slick-dots li.none {
  height: 0;
  width: 0;
  display: none;
}

.main-home .noPadding {
  padding: 0;
}

.main-home #introCopy {
  padding-top: 4rem;
  border-top: 0.2rem solid #FFF;
  padding-bottom: 4rem;
}

.main-home #introCopy h1 {
  font-family: "Nunito Extra Bold";
  color: #00b8f4;
  font-size: 3.2rem;
  line-height: 4.9rem;
  margin-top: 2rem;
  margin-bottom: 2.3rem;
}

.main-home #introCopy h1 span {
  display: block;
  font-size: 2rem;
  line-height: 3.9rem;
}

.main-home #introCopy h1 sup {
  top: -1.2em;
  font-size: 0.4em;
}

.main-home #introCopy p {
  font-family: "OpenSans-Regular";
  font-size: 1.6rem;
  line-height: 2.8rem;
  color: #373737;
  margin-top: -2rem;
}

.main-home #introCopy p a {
  color: #373737;
  font-weight: 700;
  transition: all 100ms linear;
}

.main-home #introCopy p a:hover {
  color: #00b8f4;
}

.main-home #introCopy .share-widget {
  margin-top: 2.5rem;
  font-family: "OpenSans-Regular";
  font-size: 1.3rem;
  color: #797979;
}

.main-home #newAtSandalsSlider {
  padding-top: 3rem;
}

.main-home #sandalsIntroAwards,.main-home #sandalsIntroAwards2 {
  margin: 16rem auto 0;
  display: flex;
  justify-content: center;
}

.main-home #sandalsIntroAwards .award,.main-home #sandalsIntroAwards2 .award {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

.main-home #sandalsIntroAwards .flexius,.main-home #sandalsIntroAwards2 .flexius {
  display: flex;
}

.main-home #sandalsIntroAwards .platinum-protocols,.main-home #sandalsIntroAwards2 .platinum-protocols {
  margin: 2rem 2rem 3rem;
  padding-top: 5rem;
}

.main-home #sandalsIntroAwards .platinum-protocols-container,.main-home #sandalsIntroAwards2 .platinum-protocols-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.main-home #sandalsIntroAwards .platinum-protocols-container .title,.main-home #sandalsIntroAwards2 .platinum-protocols-container .title {
  line-height: 2.0rem;
  font-size: 2.2rem;
  color: #373737;
  font-family: "OpenSans-Bold";
}

.main-home #sandalsIntroAwards .platinum-protocols-container .beaches,.main-home #sandalsIntroAwards2 .platinum-protocols-container .beaches {
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #373737;
  font-family: "OpenSans-Semibold";
}

.main-home #sandalsIntroAwards .platinum-protocols-container .link,.main-home #sandalsIntroAwards2 .platinum-protocols-container .link {
  font-size: 1.6rem;
  margin-top: 0.1rem;
  width: 22.0rem;
  height: 3rem;
  font-family: "OpenSans-Bold";
  background-color: #373737;
  color: #447daa !important;
  text-align: center;
}

.main-home #sandalsIntroAwards #wta,.main-home #sandalsIntroAwards2 #wta {
  border-right: 0.1rem solid #acacac;
  padding-right: 4.3rem;
}

.main-home #sandalsIntroAwards #wta img,.main-home #sandalsIntroAwards2 #wta img {
  height: 8rem;
  width: auto;
  display: block;
  margin: 1rem auto;
}

.main-home #sandalsIntroAwards #wta #wordStack,.main-home #sandalsIntroAwards2 #wta #wordStack {
  width: 17.3rem !important;
  display: block;
  font-family: "OpenSans-Semibold";
  font-size: 3.2rem;
  line-height: 1;
  color: #373737;
  text-transform: uppercase;
  margin: 0;
}

.main-home #sandalsIntroAwards #wta #wordStack span,.main-home #sandalsIntroAwards2 #wta #wordStack span {
  display: block;
  font-size: 1.4rem;
  line-height: 1.8rem;
}

.main-home #sandalsIntroAwards #five-star,.main-home #sandalsIntroAwards2 #five-star {
  color: #373737;
  font-size: 8rem;
  position: relative;
  top: -1.7rem;
  padding-left: 0;
}

.main-home #sandalsIntroAwards .feefoAwardImg,.main-home #sandalsIntroAwards2 .feefoAwardImg {
  height: 15rem;
  width: 15rem;
  margin: 0 5rem;
}

.main-home #resortSlideWrap {
  position: relative;
  padding-bottom: 0.2rem;
}

.main-home #resortSlideWrap .sectionNav {
  margin-top: 8.5rem;
}

.main-home #resortSlideWrap .black {
  max-height: 71rem;
  height: 50vw;
}

.main-home #resortsNav {
  padding-top: 13rem;
  margin-bottom: 13rem;
}

.main-home #resortsNav .st-vincent-blurb {
  margin: 7rem auto 0;
  text-align: center;
  padding: 2.5rem 1.6rem 0;
  color: #fff;
  background-image: url("https:://cdn.sandals.com/beaches/v12/images/resorts/bsv/home/st-vincent-home-m.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  max-width: 68.5rem;
}

.main-home #resortsNav .st-vincent-blurb .description {
  font-size: 1.1rem;
  padding: 0 3.3rem;
}

.main-home #resortsNav .st-vincent-blurb .blackButton {
  position: relative;
}

.main-home #resortsSection h2 {
  color: #00b8f4;
  font-size: 8rem;
  text-align: center;
  margin: 0;
  text-transform: none;
}

.main-home #resortsSection h2 span {
  display: block;
  color: #373737;
}

.main-home #resortsSection h3 {
  color: #373737;
}

.main-home #resortSlider {
  max-height: 71rem;
  height: 50vw;
  position: relative;
}

.main-home #resortSlider .slide-wrap,.main-home #resortSlider .slick-list,.main-home #resortSlider .slick-track,.main-home #resortSlider .slick-slide,.main-home #resortSlider .slider-item,.main-home #resortSlider .slider-item-inner {
  height: auto;
  overflow: visible;
}

.main-home #resortSlider .slick-slide > div {
  height: auto;
}

.main-home #resortSlider .slider-item-inner {
  height: 100%;
  background-size: cover;
  background-position: center;
}

.main-home #resortSlider .resort-title {
  position: relative;
  overflow: hidden;
  background-color: rgba(0, 184, 244, 0.8);
  text-align: center;
  margin-top: 7.3rem;
}

.main-home #resortSlider .resort-title i {
  display: block;
  font-size: 3.5rem;
  color: #FFF;
  padding-top: 1rem;
}

.main-home #resortSlider .resort-title h2 {
  margin: -0.3rem 0 0;
  color: #FFF;
  font-family: "Nunito Extra Bold";
  font-size: 4.5rem;
  line-height: 1.2;
}

.main-home #resortSlider .resort-title h3 {
  margin: 0;
  color: #FFF;
  font-family: "OpenSans-Semibold";
  font-size: 1.6rem;
  line-height: 1;
  text-transform: uppercase;
  padding-bottom: 1.8rem;
}

.main-home #resortSlider .floating-box {
  background-color: white;
  width: 100%;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 50%);
  margin: 0;
  bottom: 0;
  box-shadow: 0 0.3rem 2.1rem 0 rgba(0, 0, 0, 0.1);
}

.main-home #resortSlider .floating-box p {
  padding-top: 2.5rem;
  margin: 0 0 3.001rem;
  font-family: "OpenSans-Semibold";
  font-size: 1.8rem;
  line-height: 1;
  color: #373737;
}

.main-home #resortSlider .floating-box small {
  bottom: 2.5rem;
  font-family: "OpenSans-Semibold";
  font-size: 1.3rem;
  line-height: 1;
  display: inline-block;
  color: #373737;
  text-transform: uppercase;
  position: relative;
  height: 4rem;
  line-height: 4rem;
}

.main-home #resortSlider .floating-box small span.price {
  font-size: 2.4rem;
  color: #00b8f4;
}

.main-home #resortSlider .floating-box small .stack {
  display: inline-block;
  text-align: left;
  padding-left: 0.6rem;
  line-height: 0.9;
}

.main-home #resortSlider .floating-box small .stack span {
  display: block;
}

.main-home #resortSlider .floating-box small:first-of-type {
  padding-right: 2rem;
}

.main-home #resortSlider .floating-box small:first-of-type:after {
  height: 100%;
  width: 1px;
  background: #797979;
  position: absolute;
  right: 0;
  content: "";
}

.main-home #resortSlider .floating-box small:last-of-type {
  padding-left: 2rem;
}

.main-home #resortSlider .red-ribbon {
  position: absolute;
  color: #FFF;
  font-family: "OpenSans-Semibold";
  font-size: 1.4rem;
  line-height: 2.7rem;
  text-align: center;
  transform: rotate(-45deg);
  text-transform: uppercase;
  border-bottom: 3rem solid red;
  border-left: 3rem solid transparent;
  border-right: 3rem solid transparent;
  height: 0;
  width: 13.5rem;
  top: 2.2rem;
  left: -3rem;
}

.main-home .sectionCopy .copy {
  padding-top: 5rem;
  text-align: center;
}

.main-home .sectionCopy .copy .padd-xs-bot {
  padding-bottom: 9rem;
}

.main-home .sectionCopy h2 {
  font-family: "Nunito Extra Bold";
  color: #373737;
  text-transform: none;
  font-size: 7rem;
  line-height: 0.9;
}

.main-home .sectionCopy h3 {
  font-family: "OpenSans-Semibold";
  width: 100%;
  color: #00b8f4;
  font-size: 3rem;
  line-height: 1;
  padding-bottom: 2.5rem;
  border-bottom: 0.1rem solid rgba(172, 172, 172, 0.5);
  margin-bottom: 0;
  margin-top: 1.2rem;
}

.main-home .sectionCopy p {
  padding-top: 1.5rem;
  font-family: "OpenSans-Regular";
  color: #373737;
  font-size: 1.6rem;
  line-height: 2.6rem;
  margin-bottom: 0;
  margin-top: 0;
}

.main-home .sectionCopy p a {
  color: #666;
  transition: all 0.1s linear;
}

.main-home .sectionCopy p a:hover {
  color: #00b8f4;
}

.main-home .sectionCopy .blackButton {
  margin: 3rem auto 8rem;
}

.main-home .sectionNav {
  text-align: center;
  margin: 0 auto;
  position: relative;
}

.main-home .sectionNav .slide-counter {
  width: 14rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}

.main-home .sectionNav .slide-counter-inner {
  color: #797979;
  font-family: "OpenSans-Regular";
  font-size: 2.6rem;
  line-height: 1;
  position: relative;
}

.main-home .sectionNav [class^="icon"] {
  color: #797979;
  font-size: 3rem;
}

.main-home .sectionNav [class^="icon"]:before {
  font-family: "beaches-icons";
  transition: all 100ms linear;
  font-size: 27px;
  color: #797979;
  opacity: 1;
}

.main-home .sectionNav [class^="icon"]:hover:before {
  color: #00b8f4;
}

.main-home .sectionNav .slick-prev,.main-home .sectionNav .slick-next {
  height: 3rem;
  width: 1.5rem;
  top: .5rem;
}

.main-home .sectionNav .slick-prev {
  transform: none;
  left: -1.72rem;
  position: relative;
}

.main-home .sectionNav .slick-prev:before {
  content: none;
}

.main-home .sectionNav .slick-prev i:before {
  content: "\e93d";
}

.main-home .sectionNav .slick-next {
  transform: none;
  right: -1.72rem;
  position: relative;
}

.main-home .sectionNav .slick-next:before {
  content: none;
}

.main-home .sectionNav .slick-next i:before {
  content: "\e93b";
}

.main-home #suitesSection #circle-wrap {
  margin: 2rem auto 1rem;
  display: block;
}

.main-home #suitesSection #circle-wrap img {
  position: absolute;
  object-fit: cover;
  object-position: 100%;
  height: 100%;
  width: 100%;
  object-position: center;
  left: 0;
  top: 0;
}

.main-home #suitesSection #circle-wrap .circle {
  display: inline-block;
  margin: 0 -2.5vw;
}

.main-home #suitesSection #circle-wrap .circle-outer {
  padding: 1vw;
  border-radius: 50%;
  border: 0.1rem solid rgba(0, 0, 0, 0.1);
}

.main-home #suitesSection #circle-wrap .circle-inner {
  border: 0.4rem solid #FFF;
  border-radius: 50%;
  height: 20.7vw;
  width: 20.7vw;
  max-height: 29rem;
  max-width: 29rem;
  position: relative;
  overflow: hidden;
  z-index: 4;
}

.main-home #suitesSection .black {
  max-height: 107rem;
  height: 80vw;
}

.main-home #suitesSection #suiteSectionSlider {
  max-height: 107rem;
  height: 80vw;
  position: relative;
}

.main-home #suitesSection #suiteSectionSlider .sliderText {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.main-home #suitesSection #suiteSectionSlider .slideInfo {
  position: absolute;
  bottom: 4rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 6;
}

.main-home #suitesSection #suiteSectionSlider #slideTitle {
  width: 100%;
  text-align: center;
  padding-top: 10rem;
}

.main-home #suitesSection #suiteSectionSlider #slideTitle p {
  color: #FFF;
  text-transform: uppercase;
  font-family: "OpenSans-Regular";
  font-size: 2.4rem;
  border-bottom: 0.1rem solid rgba(255, 255, 255, 0.5);
  margin: 0;
  line-height: 4.3rem;
}

.main-home #suitesSection #suiteSectionSlider #slideTitle h2 {
  line-height: 1;
  color: #FFF;
  text-transform: uppercase;
  font-family: "OpenSans-Light";
  font-size: 7rem;
}

.main-home #suitesSection #suiteSectionSlider .slider-item-inner {
  height: 100%;
  background-size: cover;
  background-position: center;
}

.main-home #suitesSection #suiteSectionSlider .slide-counter {
  width: 12rem;
  margin: 0;
}

.main-home #suitesSection #suiteSectionSlider .slide-counter [class^="icon"]:before {
  color: #FFF;
}

.main-home #suitesSection #suiteSectionSlider .slide-counter [class^="icon"]:hover:before {
  color: #00b8f4;
}

.main-home #suitesSection #suiteSectionSlider .slide-counter .slide-counter-inner {
  color: #FFF;
}

.main-home #suitesSection #suiteSectionSlider .sectionNav {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-home #suitesSection #suiteSectionSlider #slide-info {
  background-color: rgba(55, 55, 55, 0.702);
  height: 3.5rem;
  position: relative;
}

.main-home #suitesSection #suiteSectionSlider #slide-info [class^="icon"] {
  padding-left: 2.5rem;
  color: #FFF;
  display: inline-block;
  line-height: 0;
  transform: rotate(-90deg);
  position: absolute;
  top: 2.3rem;
  width: 1rem;
  height: 2rem;
  left: 1rem;
}

.main-home #suitesSection #suiteSectionSlider #slide-info [class^="icon"]:before {
  color: #FFF;
  font-size: 10px;
}

.main-home #suitesSection #suiteSectionSlider #slide-info [class^="icon"]:hover {
  color: #FFF;
}

.main-home #suitesSection #suiteSectionSlider #slide-info h3 {
  padding-right: 2.5rem;
  font-size: 1.2rem;
  line-height: 3.5rem;
  display: inline-block;
  color: #FFF;
  font-family: "OpenSans-Regular";
  padding-left: 3.5rem;
  margin: 0;
}

.main-home #suitesSection #suiteSectionSlider #slide-info h3 a {
  transition: all 100ms linear;
}

.main-home #suitesSection #suiteSectionSlider #slide-info h3 a:hover {
  color: #00b8f4;
}

.main-home #suitesSection #suiteSectionSlider #slide-info h3 strong {
  font-weight: 700;
}

.main-home #suitesSection #suiteSectionSlider #slide-info #info-wrap {
  position: relative;
}

.main-home #suitesSection .copy {
  padding-top: 5.5rem;
  margin-bottom: 10rem;
}

.main-home #suitesSection .copy h5 {
  color: #00b8f4;
  font-size: 4rem;
  margin: 0;
  width: 100%;
  line-height: 1;
  border-bottom: 0.1rem solid rgba(172, 172, 172, 0.5);
}

.main-home #suitesSection .copy a {
  margin: 0 auto;
}

.main-home #suitesSection .slide-wrap,.main-home #suitesSection .slick-list,.main-home #suitesSection .slick-track,.main-home #suitesSection .slick-slide,.main-home #suitesSection .slider-item,.main-home #suitesSection .slider-item-inner {
  height: 100%;
}

.main-home #qualityInclusions .awarded-resort .logo,.main-home #qualityInclusions .luxury-rooms .logo,.main-home #qualityInclusions .aqua-center .logo {
  width: 40%;
}

.main-home #qualityInclusions .global-gourmet .logo,.main-home #qualityInclusions .pirates-island-waterpark .logo,.main-home #qualityInclusions .adventure-tours .logo {
  width: 50%;
}

.main-home #qualityInclusions .premium-spirits .logo,.main-home #qualityInclusions .redlane-spa .logo {
  width: 60%;
}

.main-home #qualityInclusions .scuba-divers .logo {
  width: 35%;
}

.main-home #qualityInclusions .teen-activities .logo,.main-home #qualityInclusions .golf-course .logo,.main-home #qualityInclusions .day-night-entertainment .logo,.main-home #qualityInclusions .weddings-and-honeymoons .logo,.main-home #qualityInclusions .day-care .logo {
  width: 30%;
}

.main-home #qualityInclusions .copy {
  margin-bottom: 0;
}

.main-home #qualityInclusions .copy h2 {
  color: #FFF;
}

.main-home #qualityInclusions .copy p {
  color: #acacac;
}

.main-home #qualityInclusions .copy.last {
  margin-bottom: 7rem;
}

.main-home #qualityInclusions .slide-wrap,.main-home #qualityInclusions .slick-list,.main-home #qualityInclusions .slick-track,.main-home #qualityInclusions .slick-slide,.main-home #qualityInclusions .slider-item,.main-home #qualityInclusions .slider-item-inner {
  height: auto;
}

.main-home #qualityInclusions .slider-item-inner {
  max-height: 61.7rem;
  height: 44.7vw;
  position: relative;
  background-size: cover;
}

.main-home #qualityInclusions .slide-counter-inner {
  font-size: 1.8rem;
  margin: 2rem 1.5rem;
  text-transform: uppercase;
}

.main-home #qualityInclusions .logo {
  position: absolute;
  left: 50%;
  bottom: 4rem;
  transform: translateX(-50%);
  width: 40%;
}

.main-home #qualityInclusions .sectionNav .ic:before {
  font-size: inherit;
}

.main-home #qualityInclusions .sectionNav [class^="icon"] {
  font-size: 3.3rem;
}

.main-home #qualityInclusions .sectionNav .slide-counter {
  width: 17rem;
}

.main-home #qualityInclusions .sectionNav .slick-next,.main-home #qualityInclusions .sectionNav .slick-prev {
  height: 3.2rem;
  width: 1.8rem;
}

.main-home #qualityInclusions .slider-item {
  margin: 0 1.5rem;
  width: 40vw;
  max-width: 55.5rem;
}

.main-home #qualityInclusions .included {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -355%);
}

.main-home #qualityInclusions .text-box {
  background: #FFF;
  text-align: center;
  position: relative;
  height: 18.7rem;
}

.main-home #qualityInclusions .text-box .text-wrap {
  width: 85%;
  margin: 0 auto;
}

.main-home #qualityInclusions .text-box p {
  margin: 1rem auto 0;
  font-family: "OpenSans-Regular";
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #373737;
  text-transform: none;
}

.main-home #qualityInclusions .text-box h3 {
  text-transform: none;
  margin: 0 auto;
  font-family: "Nunito Bold";
  font-size: 2.4rem;
  color: #373737;
  padding-top: 3.5rem;
}

.main-home #includedList .sectionCopy .copy h2 {
  font-size: 8rem;
}

.main-home #includedList .sectionCopy .copy h3 {
  border-bottom: none;
  padding-bottom: 0;
  font-size: 2.4rem;
  font-family: "OpenSans-Regular";
  font-weight: bold;
}

.main-home #includedList #list {
  margin-top: 5rem;
  background: #FFF;
  box-shadow: 0 1rem 2rem 0 rgba(0, 0, 0, 0.1);
  position: relative;
  transform: translateY(30%);
  -ms-transform: translateY(30%);
  -webkit-transform: translateY(30%);
  -moz-transform: translateY(30%);
  -o-transform: translateY(30%);
}

.main-home #includedList #list p {
  color: #373737;
  width: 100%;
  margin: 0;
  border-bottom: 0.1rem solid #acacac;
  font-family: "Nunito Bold";
  font-size: 2.8rem;
  padding-bottom: 1.5rem;
  padding-top: 6rem;
}

.main-home #includedList #list ul {
  list-style-type: none;
  margin-bottom: 6rem;
  margin-top: 1.7rem;
  margin-left: 0.3rem;
  flex-basis: calc(41.66667% - 0.6rem);
  padding: 0;
}

.main-home #includedList #list ul:last-of-type {
  margin-left: 17.33333%;
}

.main-home #includedList #list ul li {
  margin: 1.2rem 0;
  font-family: "OpenSans-Regular";
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #373737;
  padding-left: 2rem;
}

.main-home #includedList #list ul li a {
  color: #00b8f4;
  font-family: "OpenSans-Bold";
  cursor: pointer;
}

.main-home #includedList #list ul li:before {
  top: 8px;
  transform: none;
  font-size: 33px;
}

.main-home #includedList #list small {
  position: absolute;
  bottom: -3.6rem;
  font-family: "OpenSans-Regular";
  font-size: 1.2rem;
  color: #797979;
  left: 10rem;
}

.main-home #newSandals .container {
  margin-bottom: 3rem;
}

.main-home #newSandals .sectionCopy .copy {
  padding-top: 15.5rem;
}

.main-home #newSandals .sectionCopy .copy h2 {
  font-size: 10rem;
}

.main-home #newSandals .sectionCopy .copy a {
  padding: 0 5rem;
}

.main-home #newSandals .slide-wrap,.main-home #newSandals .slick-list,.main-home #newSandals .slick-track,.main-home #newSandals .slick-slide,.main-home #newSandals .slider-item,.main-home #newSandals .slider-item-inner {
  height: auto;
}

.main-home #newSandals .slider-item-inner {
  max-height: 35.6rem;
  height: 34vw;
  position: relative;
}

.main-home #newSandals .sectionNav .ic:before {
  font-size: inherit;
}

.main-home #newSandals .sectionNav [class^="icon"] {
  font-size: 3rem;
}

.main-home #newSandals .sectionNav .slide-counter {
  margin-top: 3rem;
}

.main-home #newSandals .sectionNav .slick-next,.main-home #newSandals .sectionNav .slick-prev {
  height: 3rem;
  width: 1.5rem;
}

.main-home #newSandals .slider-item {
  margin: 0 1.5rem;
  width: 40vw;
  max-width: 55.5rem;
}

.main-home #newSandals .included {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 0;
}

.main-home #newSandals .text-box {
  background: #FFF;
  width: 100%;
  height: 33rem;
  text-align: center;
  position: relative;
  box-shadow: 0 0.5rem 1.8rem 0.2rem rgba(0, 0, 0, 0.05);
}

.main-home #newSandals .text-box p {
  margin: 0 auto 2rem;
  font-family: "OpenSans-Regular";
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: #373737;
  padding-top: 1rem;
  width: 80%;
}

.main-home #newSandals .text-box p a {
  font-size: 1.4rem;
  color: #00b8f4;
  display: inline;
  font-weight: 600;
}

.main-home #newSandals .text-box h4 {
  font-family: "OpenSans-Semibold";
  font-size: 1.6rem;
  color: #797979;
  text-transform: uppercase;
  margin: 0;
  padding-top: 4.5rem;
  padding-bottom: 1rem;
}

.main-home #newSandals .text-box h3 {
  margin: -0.4rem auto 0;
  font-family: "Nunito Bold";
  font-size: 2.8rem;
  color: #373737;
}

.main-home #newSandals .text-box a {
  font-family: "OpenSans-Semibold";
  font-size: 1.8rem;
  text-transform: uppercase;
  color: #373737;
  transition: all 100ms ease-in;
  display: block;
  padding-bottom: 3rem;
}

.main-home #newSandals .text-box a:hover {
  color: #00b8f4;
}

.main-home #newSandals .text-box a i {
  color: #00b8f4;
  position: relative;
  padding-left: 0.5rem;
}

.main-home #newSandals .text-box a:focus {
  outline: none;
}

.main-home .black-button {
  text-align: center;
  margin-top: 3rem;
}

.main-home .blackButton {
  padding: 0 3rem;
  background-color: #373737;
  color: #FFF;
  text-align: center;
  font-family: "OpenSans-Semibold";
  font-size: 2rem;
  height: 4rem;
  line-height: 4rem;
  display: inline-block;
  margin: 0 auto;
  text-transform: uppercase;
  transition: all 100ms linear;
  clear: both;
  min-width: 26.4rem;
}

.main-home .blackButton:hover {
  background-color: #1e1e1e;
}

.main-home .blackButton.blue {
  background-color: #00b8f4;
}

.main-home .blackButton.blue:hover {
  background-color: #28caff;
}

.main-home .greyBG {
  background: #eee;
}

.main-home .blackBG {
  background: #1a1a1a;
}

.main-home .slick-slider:focus,.main-home .slick-track:focus,.main-home #resortSlider:focus,.main-home .slick-slide:focus {
  outline: none;
}

.main-home .included {
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 3.1rem;
  display: inline-block;
  height: 3.1rem;
  margin: 0 auto;
  padding: 0 3rem;
  text-align: center;
  text-transform: uppercase;
  background: #00b8f4;
  color: #FFF;
}

.main-home .included.optional {
  background: #797979;
}

.main-home .dsc-quote-text {
  font-size: 1.4rem;
  color: #373737;
  text-transform: uppercase;
}

.main-home .dsc-quote-text big {
  font-size: 2.9rem;
  color: #00b8f4;
}

.main-home .dsc-quote-text sup {
  top: -0.5rem;
  font-size: 60%;
}

.main-home #resortInformation {
  background: #1a1a1a;
  position: relative;
}

.main-home #resortInformation .max-width > .container {
  height: auto;
}

.main-home #resortInformationImage {
  position: relative;
  max-height: 116.3rem;
  height: 73vw;
}

.main-home #resortInformationImage img {
  object-fit: cover;
  object-position: center;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
}

.main-home #awards .sectionCopy .blackButton {
  margin: 3rem auto 0;
}

.main-home #awards span.blue {
  color: #00b8f4;
}

.main-home #resortInfo-wrap {
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  bottom: 7rem;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
}

.main-home #resortInfo-wrap .square-module {
  text-align: center;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  cursor: pointer;
  transition: all 150ms linear;
  flex-grow: 1;
  background-color: rgba(121, 121, 121, 0);
}

.main-home #resortInfo-wrap .square-module i {
  color: #FFF;
  font-size: 5rem;
  display: block;
  margin-bottom: 0.75rem;
  transition: all 150ms linear;
}

.main-home #resortInfo-wrap .square-module h4 {
  font-family: "OpenSans-Bold";
  color: #FFF;
  text-transform: uppercase;
  font-size: 2.4rem;
  margin: 0;
}

.main-home #resortInfo-wrap .square-module p {
  font-family: "OpenSans-Regular";
  font-size: 1.3rem;
  margin: 0.5rem 0 0;
  color: #FFF;
}

.main-home #resortInfo-wrap .square-module:hover {
  background-color: rgba(121, 121, 121, 0.502);
  box-shadow: 0 0.3rem 1.7rem 0.3rem rgba(0, 0, 0, 0.43);
}

.main-home #resortInfo-wrap .square-module:hover i {
  color: #FFF;
}

.main-home #sandals-box {
  background: rgba(174, 190, 197, 0.5);
  border: 0.1rem solid #FFF;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  top: 10rem;
}

.main-home #sandals-box .play-button {
  background: #00b8f4;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -3rem;
  height: 6.4rem;
  width: 6.4rem;
  border: 0.4rem solid #FFF;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  transition: all 100ms linear;
  outline: none;
}

.main-home #sandals-box .play-button i {
  font-size: 2.8rem;
  line-height: 5.7rem;
  margin: 0;
  position: relative;
  left: 0.3rem;
}

.main-home #sandals-box .play-button:hover {
  background: #0fc4ff;
}

.main-home #sandals-box .wrap {
  text-align: center;
  padding: 2rem 2rem 4rem;
}

.main-home #sandals-box i {
  color: #FFF;
  font-size: 4.6rem;
  margin-bottom: 1rem;
  display: block;
}

.main-home #sandals-box p {
  font-family: "OpenSans-Regular";
  color: #FFF;
  text-transform: uppercase;
  margin: 0;
}

.main-home #sandals-box .small {
  font-size: 1.7rem;
  line-height: 1.9rem;
}

.main-home #sandals-box .small span {
  font-size: 2rem;
  display: block;
}

.main-home #sandals-box .medium {
  font-size: 3.1rem;
  line-height: 2.9rem;
}

.main-home #sandals-box .big {
  font-size: 5.2rem;
  line-height: 4.4rem;
}

.main-home .awardsBox {
  position: relative;
  z-index: 2;
  transform: translateY(20%);
  background: #FFF;
  box-shadow: 0 1rem 2rem 0 rgba(0, 0, 0, 0.1);
}

.main-home .awardsBox img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
}

.main-home #qqBlock .detail-book {
  background: #373737;
  z-index: 2;
  position: absolute;
  border-radius: 0.4rem;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: all 400ms linear;
  bottom: -3rem;
}

.main-home #qqBlock .detail-book.show {
  max-height: 100rem;
}

.main-home #qqBlock .detail-book ul {
  list-style-type: none;
  padding-left: 3rem;
  padding-bottom: 4rem;
}

.main-home #qqBlock .detail-book .detail-book-title {
  color: #FFF;
  text-transform: uppercase;
  font-family: "OpenSans-Regular";
  font-size: 2.8rem;
  line-height: 1;
  padding-left: 3rem;
  padding-top: 4rem;
  padding-bottom: 1.5rem;
}

.main-home #qqBlock .detail-book .detail-book-title span {
  display: block;
}

.main-home #qqBlock .detail-book li {
  color: #FFF;
  font-family: "OpenSans-Regular";
  font-size: 1.4rem;
  line-height: 3rem;
}

.main-home #qqBlock .detail-book li:before {
  color: #00b8f4;
  content: "\e90f";
  font-family: "beaches-icons";
  padding-right: 5px;
  font-size: 12px;
}

.main-home #sandals {
  padding-top: 9rem;
  margin-bottom: 13rem;
}

.main-home #sandals img {
  position: relative;
  width: auto;
  max-width: 100%;
  height: auto;
}

.main-home #sandals .copy {
  position: absolute;
  top: 0;
  padding: 10rem 0 0;
}

.main-home #sandals .button {
  margin-left: 8%;
  width: 49.5rem;
  background-color: rgba(0, 184, 244, 0.7);
  border-radius: 10rem;
  margin-top: 2rem;
  transition: all 150ms linear;
  position: absolute;
  top: 59%;
}

.main-home #sandals .button:hover {
  background-color: rgba(0, 184, 244, 0.9);
}

.main-home #sandals .button .wrap {
  display: flex;
  align-items: center;
  padding: 1.5rem 0;
  margin-left: 4.5rem;
  margin-right: 2rem;
  justify-content: space-between;
}

.main-home #sandals .button .wrap > i {
  color: #FFF;
  font-size: 5rem;
}

.main-home #sandals .button .stack {
  margin-right: 3rem;
}

.main-home #sandals .button .stack span {
  display: block;
  color: #FFF;
  font-family: "OpenSans-Bold";
  font-size: 2rem;
  line-height: 1.8rem;
  text-transform: uppercase;
}

.main-home #sandals .button .circle {
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  text-align: center;
}

.main-home #sandals .button .circle i {
  color: #FFF;
  line-height: 5rem;
  font-size: 3rem;
}

.main-home #sandals h4 {
  color: #FFF;
  font-family: "OpenSans-Semibold";
  font-size: 3rem;
  width: 100%;
  border-bottom: 0.1rem solid rgba(255, 255, 255, 0.5);
  margin: 0;
  padding-bottom: 0.8rem;
}

.main-home #sandals p {
  margin: 1.3rem 0 0;
  color: #FFF;
  font-family: "OpenSans-Regular";
  font-size: 1.8rem;
  line-height: 2.4rem;
}

.main-home #home-footer {
  padding-bottom: 8rem;
}

.main-home #home-footer #enter-sweeps {
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.main-home #home-footer #enter-sweeps label {
  display: block;
  font-size: 1.8rem;
  font-family: "OpenSans-Bold";
  text-transform: uppercase;
  text-align: left;
  text-indent: 1rem;
  margin-bottom: 0.5rem;
}

.main-home #home-footer #enter-sweeps label span {
  color: #00b8f4;
}

.main-home #home-footer #enter-sweeps input {
  background: #ebebeb;
  width: 24rem;
  -webkit-appearance: none;
  border: none;
  height: 3.8rem;
  line-height: 3.8rem;
  font-size: 1.6rem;
  display: inline-block;
  vertical-align: middle;
  padding: 0 1.5rem;
  outline: none;
  -webkit-appearance: none;
}

.main-home #home-footer #enter-sweeps .has-error {
  display: none;
  position: absolute;
  left: 0;
  bottom: -3rem;
  font-size: 1.5rem;
  color: red;
  text-transform: lowercase;
  font-family: "OpenSans-Bold";
}

.main-home #home-footer #enter-sweeps .has-error--show {
  display: block;
}

.main-home #home-footer #enter-sweeps .button {
  background: #00b8f4;
  width: 2.5rem;
  height: 3.8rem;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  transition: all 100ms linear;
  border: none;
  padding: 0;
}

.main-home #home-footer #enter-sweeps .button:hover {
  background: #0fc4ff;
}

.main-home #home-footer #enter-sweeps .button i {
  color: #FFF;
  font-size: 2rem;
  line-height: 3.8rem;
}

.main-home #home-footer .top {
  text-align: center;
}

.main-home #home-footer .top > i {
  color: #00b8f4;
  font-size: 5rem;
  display: block;
}

.main-home #home-footer .top h2 {
  float: none;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  color: #373737;
  font-family: "Nunito Extra Bold";
  font-size: 5rem;
  line-height: 1;
  padding-bottom: 0.5rem;
}

.main-home #home-footer .top h2 sup {
  top: -1.5em;
  font-size: 2rem;
}

.main-home #home-footer a.js-magnific-popup {
  display: block;
  font-size: 1.2rem;
  font-weight: 600;
  color: #373737;
  text-transform: uppercase;
  text-decoration: none;
  clear: both;
  transition: all 150ms linear;
  text-align: center;
  margin-bottom: 0.5rem;
  outline: none;
}

.main-home #home-footer a.js-magnific-popup i {
  font-size: 1.2rem;
  color: #00b8f4;
}

.main-home #home-footer a.js-magnific-popup:hover {
  color: #00b8f4;
}

.main-home #home-footer .book-online {
  text-align: center;
  text-transform: uppercase;
  color: #373737;
  font-size: 1.6rem;
  font-family: "OpenSans-Regular";
  width: 100%;
  max-width: 39.5rem;
  margin: 0 auto;
  border-top: 0.1rem solid #cecece;
  padding-top: 1rem;
}

.main-home #home-footer .book-online span {
  font-weight: 700;
  color: #000;
}

.main-home #home-footer #middle-wrap {
  text-align: center;
  display: block;
  margin-top: 6rem;
  margin-bottom: 3rem;
}

.main-home #home-footer #rates-button {
  background: #00b8f4;
  width: 39.8rem;
  height: 7rem;
  text-transform: capitalize;
  line-height: 7rem;
  font-size: 3.2rem;
  color: #FFF;
  font-family: "OpenSans-Regular";
  margin: 1rem auto 1.7rem;
  display: block;
  text-align: center;
  cursor: pointer;
  transition: all 150ms linear;
  font-weight: bold;
}

.main-home #home-footer #rates-button i {
  color: #00b8f4;
  font-size: 3rem;
  position: relative;
  top: 0.1rem;
}

.main-home #home-footer #rates-button:hover {
  background: #28caff;
}

.main-home #home-footer .breaker {
  height: 7.5rem;
  display: inline-block;
  width: 0.1rem;
  background: #acacac;
  margin: 0 3.2rem 0 3.4rem;
  vertical-align: middle;
  position: relative;
  margin-top: 1.7rem;
}

.main-home #home-footer #sandals-card {
  margin-top: 3.5rem;
  display: inline-block;
  clear: auto;
  vertical-align: middle;
}

.main-home #home-footer #sandals-card img {
  height: 7rem;
  margin: 0 auto;
  display: block;
}

.main-home #home-footer #sandals-help-wrap {
  border-top: 0.1rem solid rgba(206, 206, 206, 0.5);
  border-bottom: 0.1rem solid rgba(206, 206, 206, 0.5);
}

.main-home #home-footer #sandals-help-wrap .wrap {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.main-home #home-footer #sandals-help-wrap .module a {
  display: flex;
  align-items: center;
}

.main-home #home-footer #sandals-help-wrap .module a:hover span {
  color: #00b8f4;
}

.main-home #home-footer #sandals-help-wrap .module img {
  display: inline-block;
}

.main-home #home-footer #sandals-help-wrap .module span {
  padding-left: 0.5rem;
  color: #373737;
  text-transform: uppercase;
  font-family: "OpenSans-Semibold";
  font-size: 1.4rem;
  color: #1a1a1a;
  transition: all 150ms linear;
  white-space: nowrap;
}

.main-home .slider-item-inner div.image {
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.main-home .slider-item-inner div.image img {
  height: 100%;
  max-width: none;
  width: 100%;
}

.main-home .bottom-shadow {
  width: 100%;
  position: absolute;
  bottom: 0;
  height: 12.5rem;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
  opacity: 0.6;
  z-index: 5;
}

.main-home .carouselSlider .slider-item-inner:before {
  content: "";
  border-left: 3px solid #00b8f4;
  border-top: 3px solid #00b8f4;
  border-right: 3px solid transparent;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
  animation: spinner 0.8s linear infinite;
  z-index: -1;
}

.main-home .progressive {
  position: absolute;
  display: block;
  overflow: hidden;
  outline: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.main-home .progressive:not(.replace) {
  cursor: default;
}

.main-home .progressive img {
  display: block;
  max-width: none;
  height: auto;
  border: 0 none;
  position: absolute;
  object-fit: cover;
  object-position: top center;
  width: 100%;
}

.main-home .progressive img.preview {
  filter: blur(2vw);
  transform: scale(1.05);
}

.main-home .progressive img.reveal {
  position: absolute;
  left: 0;
  top: 0;
  will-change: transform, opacity;
  animation: reveal 500ms linear;
}

.main-home .progressive.background {
  z-index: -1;
}

.main-home .progressive.background img {
  height: 100%;
}

.main-home .progressive.objectFit-backgroundImg {
  background-size: cover;
  background-position: center;
}

.main-home .progressive.objectFit-backgroundImg img {
  opacity: 0;
}

.main-home #newSandals .sectionCopy .copy h2 {
  color: #00b8f4;
}

.main-home #newSandals .sectionCopy .copy h3 {
  color: #373737;
}

.main-home #awards .sectionCopy h3 {
  color: #373737;
}

#sandalsIntroAwards .whats-included {
  max-width: 21rem;
  margin: 0 5rem;
  text-transform: uppercase;
  text-align: center;
  padding: 1.55rem 0;
  display: inline-block;
}

#sandalsIntroAwards .whats-included .icon-button-arrow-next-blue {
  color: #00b8f4;
}

#sandalsIntroAwards .whats-included .learn-more-bt {
  color: #2e2d2d;
}

#sandalsIntroAwards .whats-included #included-text {
  color: #00b8f4;
  font-size: 1.7rem;
  line-height: 1;
  font-family: "OpenSans-Semibold";
  width: 19rem;
  text-align: left;
  margin: 0 auto 0.8rem;
}

#sandalsIntroAwards .whats-included #included-text span {
  font-size: 1.4rem;
  line-height: 1.8rem;
  display: block;
}

#sandalsIntroAwards .whats-included #included-text-bt {
  margin-top: 1.5rem;
  color: #1f1f1f;
  font-size: 1.3rem;
  line-height: 1;
  font-family: "OpenSans-Regular";
  width: 23rem;
  text-align: left;
}

#sandalsIntroAwards .whats-included #included-text-bt span {
  font-size: 1.2rem;
  line-height: 1.8rem;
  display: block;
}

#sandalsIntroAwards .whats-included .btn-gray {
  background: #464646;
  display: block;
  height: 3.5rem;
  line-height: 3.5rem;
  font-size: 1.6rem;
  font-weight: 600;
  transition: all .1s linear;
}

.btc-countdown-slide span {
  display: block;
}

.btc-countdown-slide .text {
  padding-top: 2rem;
  text-align: center;
  color: #fff;
}

.btc-countdown-slide .text .text-01,.btc-countdown-slide .text .text-02 {
  text-transform: uppercase;
  font-family: "Nunito Extra Bold";
}

.btc-countdown-slide .text .text-01 {
  font-size: 1.6rem;
  line-height: 2rem;
}

.btc-countdown-slide .text .text-02 {
  font-size: 3rem;
  line-height: 3.5rem;
}

.btc-countdown-slide .text hr {
  border-color: #fff;
  opacity: .5;
  margin: 0 auto;
  max-width: 55rem;
}

.btc-countdown-slide .text .text-03 {
  font-family: "OpenSans-Semibold";
  font-size: 1rem;
  line-height: 2rem;
}

.btc-countdown-slide .countdown {
  margin: 1rem auto 0;
  padding: .5rem 0;
  background-image: radial-gradient(circle, #fff, rgba(255, 255, 255, 0));
}

.btc-countdown-slide .countdown .text-01 {
  text-transform: uppercase;
  display: block;
  color: #000;
  text-align: center;
  font-family: "Nunito Bold";
  line-height: 1rem;
  font-size: .845rem;
}

.btc-countdown-slide .countdown #countdown-slide_target {
  width: 10.5rem;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
}

.btc-countdown-slide .countdown #countdown-slide_target p {
  margin: 0;
  position: relative;
  color: #000;
  width: calc(100%/4);
  font-size: 1.4rem;
  line-height: 2rem;
  font-family: "OpenSans-Regular";
}

.btc-countdown-slide .countdown #countdown-slide_target p span {
  font-size: .5rem;
  line-height: .8rem;
  text-transform: uppercase;
}

.btc-countdown-slide .countdown #countdown-slide_target p::after {
  position: absolute;
  top: .5rem;
  right: 0rem;
  content: ':';
  display: inline;
  font-size: .8rem;
  line-height: 1rem;
}

.btc-countdown-slide .countdown #countdown-slide_target p:last-child::after {
  content: none;
}

.btc-countdown-slide .countdown.new-countdown {
  background-image: none !important;
  position: absolute;
  top: 67%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 65% !important;
}

.btc-countdown-slide .countdown.new-countdown #countdown-slide_target {
  color: white !important;
  width: 60% !important;
}

.btc-countdown-slide .countdown.new-countdown #countdown-slide_target .count-digits {
  color: white !important;
  font-size: 5.2rem;
  padding-top: .5rem;
}

.btc-countdown-slide .countdown.new-countdown #countdown-slide_target .count-digits span {
  display: none;
}

.btc-countdown-slide .countdown.new-countdown #countdown-slide_target .count-btc-digits {
  color: white !important;
  font-size: 5rem;
}

.btc-countdown-slide .countdown.new-countdown #countdown-slide_target .count-btc-digits span,.btc-countdown-slide .countdown.new-countdown #countdown-slide_target .count-btc-digits::after {
  display: none;
}

.btc-countdown-slide .countdown.new-countdown .text-01 {
  display: none !important;
}

.btc-countdown-slide img {
  height: 100%;
}

.main-home .chairman-widget {
  width: 75%;
  margin: 0 auto;
  position: relative;
  z-index: 14;
  top: 5rem;
}

.main-home .chairman-widget .opened {
  width: 100% !important;
}

.main-home .chairman-widget .chairman-photo {
  padding: 0;
  height: auto;
}

.main-home .chairman-widget .chairman-photo .chairman-img {
  margin: 1rem 0;
  width: 100%;
  height: auto;
}

.main-home .chairman-widget .chairman-widget-info {
  background-color: black;
  text-align: center;
  padding-top: 3%;
  height: auto;
}

.main-home .chairman-widget .chairman-widget-info .bm-subtitle {
  text-transform: uppercase;
  color: gray;
  font-size: 1rem;
  font-weight: 600;
  font-family: "OpenSans-Light";
  letter-spacing: 0.2rem;
}

.main-home .chairman-widget .chairman-widget-info .bm-title {
  color: white;
  font-size: 2.5rem;
  text-transform: uppercase;
  font-family: "OpenSans-Light";
  margin-bottom: 1.5rem;
}

.main-home .chairman-widget .chairman-widget-info .bm-date {
  font-size: 1.5rem;
  color: white;
  text-transform: uppercase;
  font-family: "OpenSans-Light";
}

.main-home .chairman-widget .chairman-widget-info .chairman-img-video {
  margin: 1rem 0;
  width: 70%;
}

.main-home .chairman-widget .chairman-widget-info .bm-legacy {
  font-size: 1.5rem;
  color: gold;
  text-transform: uppercase;
  font-family: "OpenSans-Light";
}

.main-home .chairman-widget .chairman-widget-info .bm-legacy i {
  margin-left: 0.5rem;
  color: white;
}

.reopening-back {
  position: relative;
  background-color: white;
}

.reopening-back .background {
  padding: 5rem 0 7rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
  background-image: url("https:://cdn.sandals.com/beaches/v12/images/home/btc-reopen/btc-background-home-mobile.png");
}

.reopening-back .background .btc-reopen-home-title {
  margin: 0 auto;
  margin-bottom: 2rem;
}

.reopening-back .background .title {
  font: 5.65rem "OpenSans-Light";
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  line-height: 4.6rem;
}

.reopening-back .background .title span {
  font-weight: bold;
  background: -webkit-linear-gradient(#eaeaea, #dcdcdc);
  font-family: "Nunito Bold";
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.reopening-back .background .subtitle {
  font: 1.33rem "OpenSans-Regular";
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.2rem;
}

.reopening-back .background .subtitle strong {
  font-family: "OpenSans-Bold";
  color: #22bcee;
}

.reopening-back .background .subtitle strong sup {
  font-size: 1.2rem;
}

.reopening-back .background .reopening-dates {
  margin: 20rem auto 0;
  padding: 4rem 1.5rem;
  background-color: rgba(11, 67, 99, 0.9);
  text-align: center;
  max-width: 40rem;
  border-radius: 2rem;
}

.reopening-back .background .resort-row {
  border-bottom: 0.1rem solid #275b76;
  padding: 1rem 0 1.2rem;
}

.reopening-back .background .resort-row:last-of-type {
  border: none;
}

.reopening-back .background .resort-opening {
  font-size: 1.05rem;
  color: #22bcee;
  text-transform: uppercase;
  font-weight: 100;
  display: inline-block;
  min-width: 12.8rem;
}

.reopening-back .background .resort-opening .two-lines {
  display: block;
}

.reopening-back .background .resort-opening span {
  font-size: 1.65rem;
}

.reopening-back .background .reopening-date {
  display: block;
  font-family: "OpenSans-Bold";
  font-size: 1.2rem;
  color: #fff;
  text-transform: uppercase;
}

.reopening-back .background .reopening-date.announcing {
  font-size: 0.9rem;
}

.reopening-back .background .reopening-date sup {
  font-size: 50%;
}

.reopening-back .background .view-reopening {
  background-color: #22bcee;
  width: 29rem;
  font-size: 1.5rem;
  display: block;
  text-align: center;
  position: relative;
  margin: 10rem auto 10rem;
  top: 30rem;
  border-radius: 1.9rem;
  text-transform: uppercase;
  height: 3.9rem;
  line-height: 3.9rem;
}

.reopening-back .background .badge {
  display: block;
  margin: 0 auto 2rem;
}

.reopening-back .background .btc-grand-offer {
  margin: 0 auto;
  padding: 0 2.8rem 1.5rem;
  text-align: center;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: 14;
  width: 100%;
  height: 100%;
  top: 45rem;
  background-image: url("https:://cdn.sandals.com/beaches/v12/images/home/btc-reopen/btc-sale-background.png");
  width: 90%;
}

.reopening-back .background .btc-grand-offer .btc-offer-title {
  padding-top: 2.2rem;
}

.reopening-back .background .btc-grand-offer .btc-offer-title .btc-reopen-view-details {
  background-color: #21527a;
  margin: 0 auto;
  width: 30%;
  color: white;
}

.reopening-back .background .btc-grand-offer .btc-offer-title .btc-reopen-view-details p {
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.5rem;
}

.reopening-back .background .protocols-info {
  margin: 0 auto;
  padding: 0 2.8rem 1.5rem;
  background-color: #fbfbfa;
  color: #363636;
  text-align: center;
  box-shadow: 0 0.3rem 2.1rem 0 rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 14;
  top: 50rem;
}

.reopening-back .background .protocols-brand {
  font: "OpenSans-Semibold";
  font-size: 2rem;
  margin: 0 0 0.2rem 0;
}

.reopening-back .background .protocols-brand sup {
  top: -0.5rem;
}

.reopening-back .background .protocols-title {
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: bold;
}

.reopening-back .background .protocols-details {
  font: "OpenSans-Semibold";
}

.reopening-back .background .learn-link {
  font-size: 1.4rem;
  text-transform: uppercase;
  color: #000;
  font-weight: 800;
}

.reopening-back .background .learn-link i {
  color: #22bcee;
  margin-left: 1.5rem;
  font-size: 1.2rem;
}

.reopening-back .background .thumbnail {
  margin: 0 auto;
  max-width: 71rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reopening-back .background .thumbnail img {
  width: 100%;
}

.reopening-back .background .thumbnail a {
  width: 6rem;
  height: 6rem;
  position: absolute;
  display: flex;
  justify-content: center;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%;
  transform: translate(0, -50%);
}

.main-home #qualityInclusions .slick-slide,.main-home #qualityInclusions .slick-item {
  height: auto;
}

.main-home #newSandals .slick-list,.main-home #newSandals .slick-slide,.main-home #newSandals .slick-track,.main-home #newSandals .slide-wrap,.main-home #newSandals .slider-item,.main-home #newSandals .slider-item-inner {
  height: auto;
}

.main-home .form-footer {
  margin: 3rem;
  background-color: #84b4c4;
  display: flex;
  flex-direction: column;
}

.main-home .form-footer .form-label {
  font-size: 1.8rem;
  font-family: "OpenSans-Regular";
  font-weight: 600;
  color: #373737;
}

.main-home .form-footer .form-label a {
  color: #00b8f4;
}

.main-home #home-news-form {
  padding: 0 3rem 3rem 3rem;
  margin: 0 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-home #home-news-form .form-header {
  padding-bottom: 2.5rem;
  text-align: center;
}

.main-home #home-news-form .form-header-title {
  font-weight: 900;
  line-height: 3rem;
  font-size: 3rem;
  margin: 0;
}

.main-home #home-news-form .form-header-paragraph {
  margin: 5px 0;
  font-weight: 500;
}

.main-home #home-news-form .form-test {
  justify-content: center;
}

.main-home #home-news-form .form-test .form-inputs-submit {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.main-home #home-news-form .form-test .form-inputs-submit .row {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
}

.main-home #home-news-form .form-test .form-inputs-submit .row .formInputBar {
  width: 100%;
  height: 3.6rem;
  border: 1px solid #ddd;
  background: #fff;
  color: #373737;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  border-radius: .5rem;
}

.main-home #home-news-form .form-test .form-inputs-submit .row .submit-button {
  background-color: #000;
  color: #fff;
  width: 28rem;
  height: 3.6rem;
  border: 1px solid #ddd;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  border-radius: .5rem;
}

.main-home #home-news-form .form-test .checkbox-info-couple {
  margin: auto;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding-bottom: 0;
}

.main-home #home-news-form .form-test .checkbox-info-couple #subscribe {
  margin-top: 2rem;
  margin-right: 1rem;
}

.main-home .thankyou-response {
  text-align: center;
  padding-bottom: 5rem;
}

.main-home .thankyou-response-title {
  font-weight: 900;
  font-size: 3rem;
  line-height: 3rem;
  margin: 0;
  padding-bottom: 2rem;
}

.main-home .thankyou-response-paragraph {
  margin: 5px 0;
  font-weight: 500;
}

.newsletter {
  display: block;
}

.newsletter .get-title {
  font-size: 4rem;
  text-align: center;
  font-weight: bolder;
}

.newsletter .get-sub {
  text-align: center;
  font-weight: bolder;
  margin-top: -0.5rem;
}

.newsletter .thankyou-txt {
  display: none;
  text-align: center;
  margin-bottom: 7rem;
}

.newsletter .thankyou-txt .thank-you {
  font-size: 2rem;
}

.newsletter .newsletter-form {
  text-align: center;
  align-items: center;
}

.newsletter .newsletter-form {
  display: block;
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.newsletter .bottom-section {
  text-align: center;
  padding-bottom: 3rem !important;
}

.newsletter .checkbox-text {
  font-size: 1.4rem;
  line-height: 2.6rem;
  font-weight: 200;
}

.newsletter .form-submit-button {
  margin-top: -1rem;
  padding: 1.5rem 1rem;
  max-width: 25rem;
  width: 100%;
  font-size: 2rem;
  line-height: 2.2rem;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  background: #1a1a1a;
}

.newsletter .form__fieldset {
  border: 0px solid white;
  margin: 0 2px;
  padding: .35em .625em .75em;
}

.newsletter .bottom-section {
  border: 0px solid white;
  margin: 0 2px;
  padding: .35em .625em .75em;
}

.newsletter .bottom-section a {
  color: #00b8f4;
}

.newsletter .subscribe-checkbox {
  margin-right: -4rem;
  margin-top: 7px;
  margin-left: 2rem;
}

.newsletter .has-error {
  font-family: "OpenSans-Regular" !important;
  font-weight: 400 !important;
  color: red;
}

.newsletter #subscribe-error {
  width: 20rem;
  bottom: -2rem;
  position: absolute;
}

.newsletter .checkbox-btn-wrapper {
  text-align: left;
}

.newsletter .ty-padding-bg {
  padding-bottom: 0.05rem;
}

@font-face {
  font-family:"beaches-icons";src:url("/assets/fonts/beaches-icons/fonts/beaches-icons.woff?v=u6b84fe89") format("woff");src:url("/assets/fonts/beaches-icons/fonts/beaches-icons.woff?v=u6b84fe89") format("woff"), url("/assets/fonts/beaches-icons/fonts/beaches-icons.ttf?v=u6b84fe89") format("truetype"), url("/assets/fonts/beaches-icons/fonts/beaches-icons.eot?v=u6b84fe89#iefix") format("embedded-opentype"), url("/assets/fonts/beaches-icons/fonts/beaches-icons.svg?v=u6b84fe89#sandals") format("svg");font-weight:normal;font-style:normal;
}

@font-face {
  font-family:"SteelfishRg-Regular";src:url("/assets/fonts/Steelfish/SteelfishRg-Regular.woff?v=u6b84fe89") format("woff");src:url("/assets/fonts/Steelfish/SteelfishRg-Regular.woff?v=u6b84fe89") format("woff"), url("/assets/fonts/Steelfish/SteelfishRg-Regular.ttf?v=u6b84fe89") format("truetype"), url("/assets/fonts/Steelfish/SteelfishRg-Regular.eot?v=u6b84fe89#iefix") format("embedded-opentype"), url("/assets/fonts/Steelfish/SteelfishRg-Regular.svg?v=u6b84fe89#SteelfishRg-Regular") format("svg");font-weight:normal;font-style:normal;
}

@font-face {
  font-family:"bodoni-book";src:url("/assets/fonts/Bodoni/Book/bodoni-book.woff?v=u6b84fe89") format("woff");src:url("/assets/fonts/Bodoni/Book/bodoni-book.woff?v=u6b84fe89") format("woff"), url("/assets/fonts/Bodoni/Book/bodoni-book.ttf?v=u6b84fe89") format("truetype"), url("/assets/fonts/Bodoni/Book/bodoni-book.eot?v=u6b84fe89#iefix") format("embedded-opentype"), url("/assets/fonts/Bodoni/Book/bodoni-book.svg?v=u6b84fe89#bodoni-book") format("svg");font-weight:normal;font-style:normal;
}

@font-face {
  font-family:"bodoni-bold";src:url("/assets/fonts/Bodoni/Bold/bodoni-bold.woff?v=u6b84fe89") format("woff");src:url("/assets/fonts/Bodoni/Bold/bodoni-bold.woff?v=u6b84fe89") format("woff"), url("/assets/fonts/Bodoni/Bold/bodoni-bold.ttf?v=u6b84fe89") format("truetype"), url("/assets/fonts/Bodoni/Bold/bodoni-bold.eot?v=u6b84fe89#iefix") format("embedded-opentype"), url("/assets/fonts/Bodoni/Bold/bodoni-bold.svg?v=u6b84fe89#bodoni-bold") format("svg");font-weight:normal;
}

@font-face {
  font-family:"bodoni-bookIta";src:url("/assets/fonts/Bodoni/BookItalic/bodoni-bookIta.woff?v=u6b84fe89") format("woff");src:url("/assets/fonts/Bodoni/BookItalic/bodoni-bookIta.woff?v=u6b84fe89") format("woff"), url("/assets/fonts/Bodoni/BookItalic/bodoni-bookIta.ttf?v=u6b84fe89") format("truetype"), url("/assets/fonts/Bodoni/BookItalic/bodoni-bookIta.eot?v=u6b84fe89#iefix") format("embedded-opentype"), url("/assets/fonts/Bodoni/BookItalic/bodoni-bookIta.svg?v=u6b84fe89#bodoni-bookIta") format("svg");font-weight:normal;font-style:normal;
}

@font-face {
  font-family:"OpenSans-Regular";src:url("/assets/fonts/OpenSans/Regular/OpenSans-Regular.woff?v=u6b84fe89") format("woff");src:url("/assets/fonts/OpenSans/Regular/OpenSans-Regular.woff?v=u6b84fe89") format("woff"), url("/assets/fonts/OpenSans/Regular/OpenSans-Regular.ttf?v=u6b84fe89") format("truetype"), url("/assets/fonts/OpenSans/Regular/OpenSans-Regular.eot?v=u6b84fe89#iefix") format("embedded-opentype"), url("/assets/fonts/OpenSans/Regular/OpenSans-Regular.svg?v=u6b84fe89#sandals") format("svg");font-weight:normal;font-style:normal;
}

@font-face {
  font-family:"OpenSans-Light";src:url("/assets/fonts/OpenSans/Light/OpenSans-Light.woff?v=u6b84fe89") format("woff");src:url("/assets/fonts/OpenSans/Light/OpenSans-Light.woff?v=u6b84fe89") format("woff"), url("/assets/fonts/OpenSans/Light/OpenSans-Light.ttf?v=u6b84fe89") format("truetype"), url("/assets/fonts/OpenSans/Light/OpenSans-Light.eot?v=u6b84fe89#iefix") format("embedded-opentype"), url("/assets/fonts/OpenSans/Light/OpenSans-Light.svg?v=u6b84fe89#sandals") format("svg");font-weight:normal;font-style:normal;
}

@font-face {
  font-family:"OpenSans-Semibold";src:url("/assets/fonts/OpenSans/Semibold/OpenSans-Semibold.woff?v=u6b84fe89") format("woff");src:url("/assets/fonts/OpenSans/Semibold/OpenSans-Semibold.woff?v=u6b84fe89") format("woff"), url("/assets/fonts/OpenSans/Semibold/OpenSans-Semibold.ttf?v=u6b84fe89") format("truetype"), url("/assets/fonts/OpenSans/Semibold/OpenSans-Semibold.eot?v=u6b84fe89#iefix") format("embedded-opentype"), url("/assets/fonts/OpenSans/Semibold/OpenSans-Semibold.eot?v=u6b84fe89");font-weight:normal;font-style:normal;
}

@font-face {
  font-family:"OpenSans-Bold";src:url("/assets/fonts/OpenSans/Bold/OpenSans-Bold.woff?v=u6b84fe89") format("woff");src:url("/assets/fonts/OpenSans/Bold/OpenSans-Bold.woff?v=u6b84fe89") format("woff"), url("/assets/fonts/OpenSans/Bold/OpenSans-Bold.ttf?v=u6b84fe89") format("truetype"), url("/assets/fonts/OpenSans/Bold/OpenSans-Bold.eot?v=u6b84fe89"), url("/assets/fonts/OpenSans/Bold/OpenSans-Bold.eot?v=u6b84fe89#iefix") format("embedded-opentype");font-weight:normal;font-style:normal;
}

@font-face {
  font-family:"Open Sans Condensed Light";src:url("/assets/fonts/OpenSans/Condensed/Light/OpenSansCondensed-Light.woff?v=u6b84fe89") format("woff");src:url("/assets/fonts/OpenSans/Condensed/Light/OpenSansCondensed-Light.woff?v=u6b84fe89") format("woff"), url("/assets/fonts/OpenSans/Condensed/Light/OpenSansCondensed-Light.ttf?v=u6b84fe89") format("truetype"), url("/assets/fonts/OpenSans/Condensed/Light/OpenSansCondensed-Light.eot?v=u6b84fe89#iefix") format("embedded-opentype"), url("/assets/fonts/OpenSans/Condensed/Light/OpenSansCondensed-Light.eot?v=u6b84fe89");font-weight:normal;font-style:normal;
}

@font-face {
  font-family:"Open Sans Condensed Bold";src:url("/assets/fonts/OpenSans/Condensed/Bold/open-sans-condensed.woff?v=u6b84fe89") format("woff");src:url("/assets/fonts/OpenSans/Condensed/Bold/open-sans-condensed.woff2?v=u6b84fe89") format("woff2"), url("/assets/fonts/OpenSans/Condensed/Bold/open-sans-condensed.woff?v=u6b84fe89") format("woff"), url("/assets/fonts/OpenSans/Condensed/Bold/open-sans-condensed.ttf?v=u6b84fe89") format("truetype"), url("/assets/fonts/OpenSans/Condensed/Bold/open-sans-condensed.eot?v=u6b84fe89#iefix") format("embedded-opentype"), url("/assets/fonts/OpenSans/Condensed/Bold/open-sans-condensed.svg?v=u6b84fe89#sandals") format("svg");font-weight:normal;font-style:normal;
}

@font-face {
  font-family:"Nunito Bold";src:url("/assets/fonts/Nunito/Bold/Nunito-Bold.woff?v=u6b84fe89") format("woff");src:url("/assets/fonts/Nunito/Bold/Nunito-Bold.woff2?v=u6b84fe89") format("woff2"), url("/assets/fonts/Nunito/Bold/Nunito-Bold.woff?v=u6b84fe89") format("woff"), url("/assets/fonts/Nunito/Bold/Nunito-Bold.ttf?v=u6b84fe89") format("truetype"), url("/assets/fonts/Nunito/Bold/Nunito-Bold.eot?v=u6b84fe89#iefix") format("embedded-opentype"), url("/assets/fonts/Nunito/Bold/Nunito-Bold.svg?v=u6b84fe89#sandals") format("svg");font-weight:normal;font-style:normal;
}

@font-face {
  font-family:"Nunito Extra Bold";src:url("/assets/fonts/Nunito/ExtraBold/Nunito-ExtraBold.woff?v=u6b84fe89") format("woff");src:url("/assets/fonts/Nunito/ExtraBold/Nunito-ExtraBold.woff2?v=u6b84fe89") format("woff2"), url("/assets/fonts/Nunito/ExtraBold/Nunito-ExtraBold.woff?v=u6b84fe89") format("woff"), url("/assets/fonts/Nunito/ExtraBold/Nunito-ExtraBold.ttf?v=u6b84fe89") format("truetype"), url("/assets/fonts/Nunito/ExtraBold/Nunito-ExtraBold.eot?v=u6b84fe89#iefix") format("embedded-opentype"), url("/assets/fonts/Nunito/ExtraBold/Nunito-ExtraBold.svg?v=u6b84fe89#sandals") format("svg");font-weight:normal;font-style:normal;
}

@font-face {
  font-family:"Nunito Semi Bold";src:url("/assets/fonts/Nunito/SemiBold/Nunito-SemiBold.woff?v=u6b84fe89") format("woff");src:url("/assets/fonts/Nunito/SemiBold/Nunito-SemiBold.woff2?v=u6b84fe89") format("woff2"), url("/assets/fonts/Nunito/SemiBold/Nunito-SemiBold.woff?v=u6b84fe89") format("woff"), url("/assets/fonts/Nunito/SemiBold/Nunito-SemiBold.ttf?v=u6b84fe89") format("truetype"), url("/assets/fonts/Nunito/SemiBold/Nunito-SemiBold.eot?v=u6b84fe89#iefix") format("embedded-opentype"), url("/assets/fonts/Nunito/SemiBold/Nunito-SemiBold.svg?v=u6b84fe89#sandals") format("svg");font-weight:normal;font-style:normal;
}

@font-face {
  font-family:"PlayfairDisplay-Regular";src:url("/assets/fonts/PlayFairDisplay/PlayfairDisplay-Regular.otf?v=u6b84fe89") format("otf");font-weight:normal;font-style:normal;
}

@font-face {
  font-family:"bebas_modregular";src:url("/assets/fonts/bebasRegular/bebasmod-regular-webfont.woff?v=u6b84fe89") format("woff");src:url("/assets/fonts/bebasRegular/bebasmod-regular-webfont.woff?v=u6b84fe89") format("woff"), url("/assets/fonts/bebasRegular/bebasmod-regular-webfont.ttf?v=u6b84fe89") format("truetype"), url("/assets/fonts/bebasRegular/bebasmod-regular-webfont.eot?v=u6b84fe89#iefix") format("embedded-opentype"), url("/assets/fonts/bebasRegular/bebasmod-regular-webfont.svg?v=u6b84fe89#SteelfishRg-Regular") format("svg");font-weight:normal;font-style:normal;
}

@font-face {
  font-family:"GoodFoot-Regular";src:url("/assets/fonts/good-foot/GoodFoot-Regular.woff?v=u6b84fe89") format("woff");src:url("/assets/fonts/good-foot/GoodFoot-Regular.woff?v=u6b84fe89") format("woff"), url("/assets/fonts/good-foot/GoodFoot-Regular.ttf?v=u6b84fe89") format("truetype"), url("/assets/fonts/good-foot/GoodFoot-Regular.eot?v=u6b84fe89#iefix") format("embedded-opentype"), url("/assets/fonts/good-foot/GoodFoot-Regular.svg?v=u6b84fe89#SteelfishRg-Regular") format("svg");font-weight:normal;font-style:normal;
}

@media (min-width: 320px) {
  .main-home .chairman-widget .chairman-photo {
    margin-bottom: -1rem;
    height: auto;
  }
}

@media (min-width: 375px) {
  #sandalsIntroAwards .whats-included #included-text {
    text-align: center;
  }

  #sandalsIntroAwards .whats-included #included-text-bt {
    text-align: center;
  }
}

@media screen and (min-width: 440px) and (max-width: 549px) {
  .main-home #sandals .copy {
    top: 34% !important;
  }
}

@media screen and (min-width: 550px) and (max-width: 767px) {
  .main-home #sandals .copy {
    top: 26% !important;
  }

  .main-home #sandals .button {
    margin-left: 1rem !important;
    top: 58% !important;
    transform: none !important;
    left: auto !important;
  }
}

@media (min-width: 768px) {
  .main-home .mobileItems {
    display: none;
  }

  .main-home .desktopItems {
    display: block;
  }

  .main-home #coronavirus-badge #corona-virus {
    width: 18.2rem;
    right: -1.5rem;
    top: -66vw;
  }

  .main-home #hero-slider {
    max-height: 50.28rem;
    height: 84vw;
  }

  .main-home #hero-slider > .container:not(.info) {
    position: relative;
  }

  .main-home #hero-slider .slick-dots {
    display: none !important;
  }

  .main-home #introCopy .dsc-counter-foot .link i {
    top: -0.2rem;
  }

  .main-home #sandalsIntroAwards #five-star,.main-home #sandalsIntroAwards2 #five-star {
    padding-top: 5rem;
  }

  .main-home #resortsNav .st-vincent-blurb {
    background-image: url("https:://cdn.sandals.com/beaches/v12/images/resorts/bsv/home/st-vincent-home.jpg");
    padding: 0 4.3rem 1.5rem 22.3rem;
    border-radius: .5rem;
  }

  .main-home #resortsNav .st-vincent-blurb .blackButton {
    font-size: 1.8rem;
    height: 2.8rem;
    line-height: 2.8rem;
    min-width: 21.4rem;
    padding: 0;
  }

  .main-home #resortSlider .floating-box small {
    position: relative;
    top: -1.2rem;
  }

  .main-home #home-footer #middle-wrap {
    margin-right: -5rem;
  }

  #sandalsIntroAwards .whats-included #included-text {
    width: 35rem;
  }

  #sandalsIntroAwards .whats-included #included-text-bt {
    width: 35rem;
  }

  .btc-countdown-slide .text {
    padding-top: 4rem;
  }

  .btc-countdown-slide .text .text-01 {
    font-size: 3.2rem;
    line-height: 3.5rem;
  }

  .btc-countdown-slide .text .text-02 {
    font-size: 6.1rem;
    line-height: 6.5rem;
  }

  .btc-countdown-slide .text .text-03 {
    font-size: 2.1rem;
    line-height: 4rem;
  }

  .btc-countdown-slide .countdown {
    padding: 1.5rem 0;
    margin-top: 2rem;
    width: 56rem;
  }

  .btc-countdown-slide .countdown .text-01 {
    line-height: 2rem;
    font-size: 1.7rem;
  }

  .btc-countdown-slide .countdown #countdown-slide_target {
    width: 20.5rem;
  }

  .btc-countdown-slide .countdown #countdown-slide_target p::after {
    top: .75rem;
    font-size: 1.6rem;
    line-height: 1.8rem;
  }

  .main-home .chairman-widget .chairman-photo {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }

  .reopening-back {
    margin: 0;
  }

  .reopening-back .background {
    background-image: url("https:://cdn.sandals.com/beaches/v12/images/home/btc-reopen/btc-background-home-tablet.png");
    padding: 6rem 0 6rem;
  }

  .reopening-back .background .title {
    font-size: 11.38rem;
    line-height: 7.9rem;
  }

  .reopening-back .background .subtitle {
    font-size: 3rem;
  }

  .reopening-back .background .reopening-dates {
    margin-top: 5rem;
    padding: 5rem 5.2rem 4rem;
  }

  .reopening-back .background .resort-row {
    padding: 1.8rem 0 1.9rem;
  }

  .reopening-back .background .resort-opening {
    font-size: 1.4rem;
  }

  .reopening-back .background .resort-opening span {
    font-size: 2.2rem;
  }

  .reopening-back .background .reopening-date {
    font-size: 1.9rem;
  }

  .reopening-back .background .reopening-date.announcing {
    font-size: 1rem;
  }

  .reopening-back .background .view-reopening {
    font-size: 2rem;
    width: 38rem;
    height: 5.9rem;
    line-height: 5.9rem;
    border-radius: 5rem;
  }

  .reopening-back .background .view-reopening {
    position: relative;
    margin: 10rem auto 10rem;
    top: 30rem;
  }

  .reopening-back .background .badge {
    margin-bottom: 0;
    margin-right: 2.4rem;
  }

  .reopening-back .background .protocols-info {
    display: flex;
    align-items: center;
    max-width: 86rem;
    padding: 0 5.2rem 0 2.2rem;
    text-align: left;
    top: 50rem;
  }

  .reopening-back .background .protocols-title {
    margin-bottom: 1rem;
    margin-top: 2rem;
  }

  .reopening-back .background .learn-link {
    margin-bottom: 3.2rem;
  }

  .reopening-back .background .thumbnail {
    max-width: 107rem;
  }

  .reopening-back .background .thumbnail a {
    width: 11rem;
    height: 11rem;
  }

  .reopening-back .background .thumbnail a {
    width: 14.5rem;
    height: 14.5rem;
  }

  .main-home #home-news-form .form-test .form-inputs-submit .row .formInputBar {
    width: 88%;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .main-home #intro-copy-row #sandalsIntroAwards,.main-home #intro-copy-row #sandalsIntroAwards2 {
    flex-flow: column;
  }

  .main-home #intro-copy-row #sandalsIntroAwards #wta,.main-home #intro-copy-row #sandalsIntroAwards2 #wta {
    border-bottom: 0.1rem solid #acacac;
    padding-right: 0;
    border-right: 0;
    padding-bottom: 4rem;
    text-align: center;
  }

  .main-home #intro-copy-row #sandalsIntroAwards .whats-included,.main-home #intro-copy-row #sandalsIntroAwards2 .whats-included {
    margin-left: auto;
  }

  .main-home .tablet-show {
    display: block !important;
  }

  .main-home #introCopy h1 {
    margin-bottom: 1rem;
  }

  .main-home #newAtSandalsSlider {
    padding-top: 0.5rem;
  }

  .main-home #sandalsIntroAwards #five-star,.main-home #sandalsIntroAwards2 #five-star {
    padding-top: 7rem;
  }

  .main-home #resortSlideWrap .black {
    min-height: 53.4rem;
    height: 69vw;
  }

  .main-home #resortsNav {
    margin-bottom: 8rem;
  }

  .main-home #resortsSection h2 {
    font-size: 7.5rem;
  }

  .main-home #resortsSection h3 {
    margin-top: 1.4rem;
  }

  .main-home #resortsSection .copy .padd-xs-bot {
    padding-bottom: 13rem;
  }

  .main-home #resortSlider .resort-title {
    margin-top: -9.5rem;
    background-color: #00b8f4;
    max-width: 71.5rem;
    margin: -9.5rem auto 0;
    padding-top: 2rem;
  }

  .main-home #resortSlider {
    min-height: 53.4rem;
    height: 69vw;
  }

  .main-home .sectionCopy .copy {
    padding-top: 8rem;
  }

  .main-home #suitesSection .black {
    min-height: 80.4rem;
    height: 104vw;
  }

  .main-home #suitesSection #suiteSectionSlider {
    min-height: 80.4rem;
    height: 104vw;
  }

  .main-home #suitesSection #suiteSectionSlider #slideTitle {
    padding-top: 8rem;
  }

  .main-home #qualityInclusions .copy {
    margin-bottom: 2.5rem;
  }

  .main-home #qualityInclusions .slider-item {
    width: 72vw;
    min-width: 55.5rem;
  }

  .main-home #qualityInclusions .slider-item-inner {
    height: 67vw;
    width: 100%;
    min-height: 61rem;
  }

  .main-home #includedList #list ul {
    flex-basis: calc(50% - 0.6rem);
  }

  .main-home #includedList #list ul:last-of-type {
    margin-left: 0.3rem;
  }

  .main-home #includedList #list ul:first-of-type {
    padding-right: 1.5rem;
  }

  .main-home #includedList #list .copy {
    margin-left: 0;
    margin-right: 0;
  }

  .main-home #newSandals .slider-item {
    width: 72vw;
    min-width: 55.5rem;
  }

  .main-home #newSandals .slider-item-inner {
    height: 40vw;
    width: 100%;
    min-height: 35.5rem;
  }

  .main-home #resortInformationImage {
    min-height: 104rem;
    height: 50vw;
  }

  .main-home #resortInfo-wrap .square-module h4 span {
    display: block;
  }

  .main-home #resortInfo-wrap .square-module p {
    width: 80%;
    line-height: 1.8rem;
    margin: 0.5rem auto 0;
  }

  .main-home #sandals-box {
    top: 15rem;
  }

  .main-home .awardsBox {
    padding: 0.9rem 9.7rem 1.1rem;
  }

  .main-home #qqBlock {
    margin: 0px auto;
    margin-top: 4rem;
  }

  .main-home #qqBlock .discount-block {
    max-width: none;
    width: 100%;
  }

  .main-home #qqBlock .discount-block .dsc-counter-top {
    line-height: 2.6rem;
  }

  .main-home #qqBlock .discount-block .dsc-counter-top span {
    display: inline-block;
  }

  .main-home #qqBlock .discount-block .dsc-counter-foot {
    padding: 1rem 0;
  }

  .main-home #qqBlock #count-promo-wrap {
    display: flex;
    align-items: center;
  }

  .main-home #qqBlock #count-promo-wrap > div {
    flex-basis: 0;
    flex-grow: 1;
  }

  .main-home #sandals h4 {
    font-size: 2.4rem;
  }

  .main-home #sandals p {
    font-size: 1.4rem;
    line-height: 1;
    margin: 1rem 0 0;
  }

  .main-home #sandals .copy {
    padding: 7.5rem 0 0;
    margin-left: 2.5rem;
  }

  .main-home #sandals .button {
    margin-left: 2rem;
    top: 51%;
    width: 37rem;
  }

  .main-home #sandals .button .wrap {
    padding: 1rem 0;
    margin-right: 1rem;
  }

  .main-home #sandals .button .wrap > i {
    font-size: 4rem;
  }

  .main-home #sandals .button .stack {
    margin-right: 0rem;
  }

  .main-home #sandals .button .stack span {
    font-size: 1.5rem;
    line-height: 1.35rem;
  }

  .main-home #sandals .button .circle {
    height: 3.7rem;
    width: 3.7rem;
  }

  .main-home #sandals .button .circle i {
    line-height: 3.7rem;
    font-size: 1.6rem;
  }

  .main-home #home-footer {
    margin-top: -2rem;
  }

  .main-home #home-footer .top h2 {
    font-size: 4.9rem;
    padding: 0 0 0.5rem;
  }

  .main-home #home-footer #sandals-help-wrap .wrap {
    justify-content: space-between;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .main-home #sandalsIntroAwards,.main-home #sandalsIntroAwards2 {
    flex-flow: column;
  }

  .main-home #sandalsIntroAwards #wta,.main-home #sandalsIntroAwards2 #wta {
    border-bottom: 0.1rem solid #acacac;
    padding-right: 0;
    border-right: 0;
    padding-bottom: 4rem;
    text-align: center;
    margin: 0 auto;
  }

  .main-home #sandalsIntroAwards .feefoAwardImg,.main-home #sandalsIntroAwards2 .feefoAwardImg {
    margin: 3rem auto;
  }
}

@media (min-width: 1024px) {
  .main-home #coronavirus-badge #corona-virus {
    top: -53vw;
  }

  .main-home #coronavirus-badge #corona-virus {
    top: -58vw;
  }

  .main-home #intro-copy-row {
    align-items: flex-start;
    align-content: flex-start;
  }

  .main-home #intro-copy-row #first-order {
    order: 1;
    height: auto;
  }

  .main-home #intro-copy-row #third-order {
    order: 3;
  }

  .main-home #intro-copy-row #qqBlock {
    order: 2;
    height: 0;
  }

  .main-home #intro-copy-row #sandalsIntroAwards,.main-home #intro-copy-row #sandalsIntroAwards2 {
    flex-flow: row;
    margin: 2rem auto 0;
  }

  .main-home #intro-copy-row #sandalsIntroAwards > div,.main-home #intro-copy-row #sandalsIntroAwards2 > div {
    flex-grow: 1;
  }

  .main-home #intro-copy-row #wta {
    text-align: center;
    align-self: flex-end;
  }

  .main-home #intro-copy-row #wta img {
    margin: 0 auto;
    display: block;
  }

  .main-home #intro-copy-row .whats-included {
    margin-left: auto;
    max-width: none;
  }

  .main-home #intro-copy-row .whats-included a {
    width: 22.5rem;
    margin: 0 auto;
  }

  .main-home #hero-slider {
    max-height: 88.28rem;
    height: 69vw;
  }

  .main-home .DayPicker {
    left: -32.5rem;
  }

  .main-home #newAtSandalsSlider {
    padding-top: 2.5rem;
  }

  .main-home #sandalsIntroAwards,.main-home #sandalsIntroAwards2 {
 /*margin: -30rem auto 0;*/;
  }

  .main-home #resortsSection {
    padding-top: 4rem;
  }

  .main-home #qualityInclusions .copy h2,.main-home #includedList .copy h2 {
    font-size: 8rem;
  }

  .main-home #includedList {
    margin-top: 2rem;
  }

  .main-home #resortSlider .resort-title {
    background: none;
  }

  #sandalsIntroAwards .whats-included #included-text-bt {
    width: 35rem;
  }

  .btc-countdown-slide .countdown.new-countdown {
    width: 50% !important;
  }

  .btc-countdown-slide .countdown.new-countdown #countdown-slide_target {
    width: 60% !important;
  }

  .btc-countdown-slide .countdown.new-countdown #countdown-slide_target .count-digits {
    font-size: 5rem;
  }

  .main-home .chairman-widget .chairman-widget-info {
    padding-bottom: 1rem;
  }

  .main-home .chairman-widget .chairman-widget-info .chairman-img-video {
    width: 50%;
  }

  .reopening-back .background {
    background-image: url("https:://cdn.sandals.com/beaches/v12/images/home/btc-reopen/btc-background-home.png");
  }

  .reopening-back .background .protocols-info {
    top: 55rem;
  }

  .main-home #home-news-form .form-test .form-inputs-submit {
    flex-direction: row;
  }
}

@media (min-width: 1024px) and (max-width: 1199px) {
  .main-home #sandals .copy {
    flex-basis: 62%;
    max-width: 62%;
    margin-left: 2%;
    padding: 8rem 0 0;
  }

  .main-home #sandals .button {
    margin-left: 2%;
    top: 59%;
    width: 47.5rem;
  }
}

@media screen and (min-width: 1025px) {
  .main-home #sandalsIntroAwards .platinum-protocols,.main-home #sandalsIntroAwards2 .platinum-protocols {
    padding-top: 0;
  }
}

@media (min-width: 1200px) {
  .main-home #coronavirus-badge #corona-virus {
    top: -48vw;
  }

  .main-home #hero-slider {
    height: 59vw;
  }

  .main-home .DayPicker {
    left: -26.2rem;
  }

  .main-home #resortsNav .st-vincent-blurb {
    max-width: 93.5rem;
    padding: 1.3rem 5.2rem 6rem 37.4rem;
  }

  .main-home #resortsNav .st-vincent-blurb .description {
    font-size: 1.6rem;
  }

  .main-home .awardsBox {
    padding: 3.7rem 6.4rem 5rem;
  }

  .main-home #sandals .copy {
    max-width: 56.66667%;
    flex-basis: 56.66667%;
  }

  #sandalsIntroAwards .whats-included #included-text {
    text-align: left;
    width: 20rem;
  }

  #sandalsIntroAwards .whats-included #included-text-bt {
    width: 35rem;
  }

  .btc-countdown-slide .text {
    padding-top: 6rem;
  }

  .btc-countdown-slide .text .text-01 {
    line-height: 4rem;
    font-size: 3.9rem;
  }

  .btc-countdown-slide .text .text-02 {
    line-height: 7.5rem;
    font-size: 7.3rem;
  }

  .btc-countdown-slide .text hr {
    margin: 1rem auto;
    width: 83rem;
    max-width: 100%;
  }

  .btc-countdown-slide .text .text-03 {
    line-height: 3rem;
    font-size: 2.5rem;
  }

  .btc-countdown-slide .countdown {
    width: 70rem;
  }

  .btc-countdown-slide .countdown .text-01 {
    line-height: 2.5rem;
    font-size: 2rem;
  }

  .btc-countdown-slide .countdown #countdown-slide_target {
    width: 24rem;
  }

  .btc-countdown-slide .countdown #countdown-slide_target p::after {
    top: 1.5rem;
    font-size: 1.9rem;
    line-height: 2rem;
  }

  .reopening-back .background {
    background-image: url("https:://cdn.sandals.com/beaches/v12/images/home/btc-reopen/btc-background-home.png");
    padding: 4rem 0 5rem;
  }

  .reopening-back .background .title {
    font-size: 15.1rem;
  }

  .reopening-back .background .subtitle {
    font-size: 3rem;
    margin-top: 3rem;
  }

  .reopening-back .background .badge {
    margin: 0 5rem 0;
  }

  .reopening-back .background .protocols-info {
    margin: 0 2rem 0;
    max-width: 107.3rem;
    top: 55rem;
  }

  .reopening-back .background .protocols-title {
    font-size: 3.6rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .reopening-back .background .protocols-details {
    font-size: 1.4rem;
    line-height: 2.3rem;
    width: 70rem;
  }

  .main-home #home-news-form {
    padding: 0 9rem;
    margin: 0 3rem;
  }

  .main-home #home-news-form .form-test {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

@media (min-width: 1400px) {
  #sandalsIntroAwards .whats-included #included-text-bt {
    text-align: left;
  }
}

@media (min-width: 1440px) {
  .main-home .chairman-widget .chairman-widget-info {
    padding-top: 5%;
  }

  .main-home .chairman-widget .chairman-widget-info .chairman-img-video {
    width: 50%;
  }
}

@media (min-width: 1920px) {
  .main-home #coronavirus-badge #corona-virus {
    top: -93rem;
  }
}

@media (max-width: 1199px) {
  #sandalsIntroAwards .whats-included {
    display: block;
    margin: .5rem auto 4rem;
    max-width: 45rem;
  }

  .btc-countdown-slide .countdown.new-countdown #countdown-slide_target .count-digits {
    font-size: 4.5rem;
  }

  .reopening-back .background .subtitle {
    font-size: 2.5rem;
  }
}

@media (max-width: 1024px) {
  #sandalsIntroAwards2 {
    margin: -2rem auto 0 !important;
  }
}

@media (max-width: 1023px) {
  .mfp-wrap .best-price-guarantee-popup {
    overflow-y: auto;
  }

  .main-home {
    margin-top: -3.2rem;
  }

  .main-home .desktop-show {
    display: none !important;
  }

  .main-home #introCopy p {
    margin-top: -1rem;
  }

  .main-home #newSandals .sectionCopy .copy {
    padding-top: 8rem;
  }

  .btc-countdown-slide .countdown.new-countdown #countdown-slide_target .count-btc-digits {
    font-size: 4.5rem;
  }

  .main-home .chairman-widget {
    width: 80%;
  }

  .reopening-back .background .btc-grand-offer .btc-offer-title .btc-reopen-view-details {
    width: 35%;
  }

  .reopening-back .background .btc-grand-offer .btc-offer-title .btc-reopen-view-details p {
    font-size: 1.2rem;
  }
}

@media (max-width: 896px) {
  .main-home .chairman-widget .chairman-widget-info .bm-title {
    font-size: 2.2rem;
  }
}

@media (max-width: 768px) {
  .btc-countdown-slide .countdown.new-countdown {
    width: 58% !important;
  }

  .newsletter .checkbox-text {
    font-size: 1.3rem;
  }
}

@media (max-width: 767px) {
  .mfp-wrap .best-price-guarantee-popup {
    padding-right: 1.5rem;
    padding-bottom: 3.6rem;
    padding-left: 1.5rem;
    margin: 1.5rem auto;
  }

  .best-price-guarantee-popup .popup-content {
    display: block;
  }

  .best-price-guarantee-popup .popup-content-text {
    width: 100%;
  }

  .best-price-guarantee-popup .popup-content img {
    padding: 2rem;
  }

  .main-home {
    margin-top: -1.2rem;
  }

  .main-home #coronavirus-badge.container {
    height: auto !important;
    top: 80% !important;
  }

  .main-home .mobile-show {
    display: block !important;
  }

  .main-home #hero-slider .slick-next,.main-home #hero-slider .slick-prev {
    display: none !important;
  }

  .main-home #hero-slider .slide-counter {
    position: absolute;
    bottom: -1rem;
    right: 0;
  }

  .main-home #hero-slider > .container {
    top: calc(100% - 3rem);
    height: 3rem;
    width: 100%;
  }

  .main-home #hero-slider .slide-counter-inner {
    font-size: 2rem;
    display: block;
    text-align: center;
    width: 100%;
  }

  .main-home #hero-slider .slide-info {
    border-top: none;
  }

  .main-home #hero-slider .slide-nav {
    width: 12rem;
    display: block;
    margin: 0 auto;
  }

  .main-home #introCopy p {
    margin-top: 0rem;
    font-size: 1.4rem;
    line-height: 2.45rem;
  }

  .main-home #introCopy {
    padding-bottom: 6.5rem;
    padding-top: 1rem;
  }

  .main-home #introCopy h1 {
    margin-bottom: 1.5rem;
    line-height: 3.8rem;
  }

  .main-home #introCopy h1 span {
    line-height: 3.9rem;
  }

  .main-home #introCopy .share-widget {
    margin-top: 1.5rem;
  }

  .main-home #sandalsIntroAwards,.main-home #sandalsIntroAwards2 {
    flex-direction: column;
  }

  .main-home #sandalsIntroAwards #wta,.main-home #sandalsIntroAwards2 #wta {
    border-right: none;
    border-bottom: 0.1rem solid #acacac;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 2rem 0;
  }

  .main-home #sandalsIntroAwards #wta img,.main-home #sandalsIntroAwards2 #wta img {
    height: 8rem;
  }

  .main-home #sandalsIntroAwards #wta #wordStack,.main-home #sandalsIntroAwards2 #wta #wordStack {
    font-size: 1.65rem;
  }

  .main-home #sandalsIntroAwards #wta #wordStack span,.main-home #sandalsIntroAwards2 #wta #wordStack span {
    font-size: 1.05rem;
    line-height: 1.35rem;
  }

  .main-home #sandalsIntroAwards #five-star,.main-home #sandalsIntroAwards2 #five-star {
    top: 0;
    font-size: 6rem;
  }

  .main-home #sandalsIntroAwards .award,.main-home #sandalsIntroAwards2 .award {
    padding: 1.55rem 0 !important;
    text-align: center;
    display: block;
    margin: 0 auto;
  }

  .main-home #sandalsIntroAwards .feefoAwardImg,.main-home #sandalsIntroAwards2 .feefoAwardImg {
    margin: 3rem auto;
  }

  .main-home #resortSlideWrap {
    padding-bottom: 2rem;
  }

  .main-home #resortSlideWrap .sectionNav {
    margin-top: 3.5rem;
  }

  .main-home #resortSlideWrap .black {
    height: 95vw;
    min-height: 31.4rem;
  }

  .main-home #resortsNav {
    padding-top: 0;
    margin-bottom: 6rem;
    margin-top: 3.5rem;
  }

  .main-home #resortsNav .st-vincent-blurb .blackButton {
    top: 1.4rem;
  }

  .main-home #resortsSection h2 {
    font-size: 4rem;
  }

  .main-home #resortsSection .copy {
    padding-top: 6.5rem;
  }

  .main-home #resortsSection .padd-xs-bot {
    padding-bottom: 9rem;
  }

  .main-home #resortSlider .slider-item {
    height: 86%;
  }

  .main-home #resortSlider .slider-item-inner {
    height: inherit;
  }

  .main-home #resortSlider .resort-title {
    margin-top: -6.6rem;
    background-color: #00b8f4;
  }

  .main-home #resortSlider .floating-box {
    height: auto;
    bottom: 2rem;
    background-color: white;
    box-shadow: none;
  }

  .main-home #resortSlider {
    height: 95vw;
    min-height: 31.4rem;
  }

  .main-home #resortSlider .resort-title {
    padding: 0;
  }

  .main-home #resortSlider .resort-title .red-ribbon {
    border-bottom: 1.8rem solid red;
    border-left: 1.8rem solid transparent;
    border-right: 1.8rem solid transparent;
    line-height: 1.8rem;
    font-size: 0.9rem;
    width: 10.5rem;
    top: 1.2rem;
  }

  .main-home #resortSlider .resort-title i {
    font-size: 2.4rem;
    padding-top: 1rem;
  }

  .main-home #resortSlider .resort-title h2 {
    font-size: 2.25rem;
    margin-top: 1rem;
  }

  .main-home #resortSlider .resort-title h3 {
    font-size: 1rem;
    padding-bottom: 1.5rem;
  }

  .main-home #resortSlider .floating-box p {
    width: 100%;
    font-size: 1.35rem;
    padding-top: 1.7rem;
    margin-bottom: 0;
  }

  .main-home #resortSlider .floating-box small {
    font-size: 1.3rem;
    margin-bottom: 2.25rem;
    bottom: 0;
    display: block;
    padding: 0 !important;
    height: auto;
    line-height: 1;
  }

  .main-home #resortSlider .floating-box small:first-of-type {
    margin-bottom: 0.8rem;
  }

  .main-home #resortSlider .floating-box small:first-of-type:after {
    content: none;
  }

  .main-home .sectionCopy .copy {
    padding-top: 5.5rem;
  }

  .main-home .sectionCopy h2 {
    font-size: 4rem;
    margin-bottom: 1rem;
  }

  .main-home .sectionCopy h3 {
    font-size: 1.5rem;
    padding-bottom: 1rem;
    margin-top: 0.5rem;
  }

  .main-home .sectionCopy p {
    padding-top: 0.5rem;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .main-home .sectionCopy .blackButton {
    font-size: 2rem;
    padding: 0;
    min-width: 27.5rem;
    height: 3.6rem;
    line-height: 3.6rem;
    margin: 2rem auto;
  }

  .main-home .sectionNav .slide-counter-inner {
    font-size: 2rem;
  }

  .main-home .sectionNav [class^="icon"] {
    font-size: 2.5rem;
  }

  .main-home .sectionNav .slick-prev,.main-home .sectionNav .slick-next {
    height: 2.5rem;
    width: 1.25rem;
  }

  .main-home .sectionNav .slide-counter {
    width: 11rem;
  }

  .main-home #suitesSection #circle-wrap {
    padding: 0;
    margin: 1rem auto 2.5rem;
  }

  .main-home #suitesSection #circle-wrap .circle-inner {
    border-width: 0.3rem;
    height: 31.7vw;
    width: 31.7vw;
    min-width: 10.25rem;
    min-height: 10.25rem;
  }

  .main-home #suitesSection .copy {
    padding-top: 3rem;
    margin-bottom: 7.5rem;
  }

  .main-home #suitesSection .black {
    min-height: 40rem;
    height: 100vw;
  }

  .main-home #suitesSection #suiteSectionSlider {
    min-height: 40rem;
    height: 100vw;
  }

  .main-home #suitesSection #suiteSectionSlider .sectionNav {
    flex-flow: column;
  }

  .main-home #suitesSection #suiteSectionSlider .slide-counter {
    margin-bottom: 0.7rem;
  }

  .main-home #suitesSection #suiteSectionSlider #slide-info {
    height: 2.5rem;
    width: 100%;
  }

  .main-home #suitesSection #suiteSectionSlider #slide-info h3 {
    font-size: 0.9rem;
    line-height: 2.5rem;
    padding-right: 0;
    padding-left: 2.2rem;
    top: -0.3rem;
    position: relative;
  }

  .main-home #suitesSection #suiteSectionSlider #slide-info [class^="icon"] {
    top: 1.5rem;
    left: auto;
  }

  .main-home #suitesSection #suiteSectionSlider #slide-info #info-wrap {
    display: inline-flex;
    align-items: center;
    text-align: center;
  }

  .main-home #suitesSection #suiteSectionSlider #slideTitle {
    padding-top: 3.5rem;
  }

  .main-home #suitesSection #suiteSectionSlider #slideTitle h2 {
    font-size: 3.5rem;
  }

  .main-home #suitesSection #suiteSectionSlider #slideTitle p {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }

  .main-home #suitesSection #suiteSectionSlider #slide-info {
    order: 2;
  }

  .main-home #suitesSection #suiteSectionSlider .slideInfo {
    padding: 0;
    bottom: 0;
  }

  .main-home #suitesSection #suiteSectionSlider .container {
    width: 100%;
    left: 0;
    transform: none;
  }

  .main-home #qualityInclusions .copy.last {
    margin-bottom: 5rem;
  }

  .main-home #qualityInclusions .sectionCopy .copy:not(.last) {
    margin-bottom: 5rem;
  }

  .main-home #qualityInclusions .sectionCopy .copy:not(.last) h2 {
    margin-bottom: 0.1rem;
  }

  .main-home #qualityInclusions .slick-slide {
    margin: 0;
  }

  .main-home #qualityInclusions .slick-slide > div {
    margin: 0 0.75rem;
  }

  .main-home #qualityInclusions .copy {
    margin-bottom: 2.5rem;
  }

  .main-home #qualityInclusions #qualityInclusionsSlider {
    margin-left: 0;
  }

  .main-home #qualityInclusions .text-wrap {
    width: 100%;
  }

  .main-home #qualityInclusions .text-box h3 {
    width: 90%;
  }

  .main-home #qualityInclusions .slider-item {
    min-width: 27.15rem;
    width: 60vw !important;
    margin: 0;
  }

  .main-home #qualityInclusions .slick-list {
    padding: 0 18% 0 0 !important;
  }

  .main-home #qualityInclusions .logo {
    bottom: 2rem;
  }

  .main-home #qualityInclusions .slider-item-inner {
    height: 67vw;
    width: 100%;
    min-height: 30rem;
  }

  .main-home #qualityInclusions .slider-item-inner .slide-image {
    width: 100%;
    position: absolute;
  }

  .main-home #qualityInclusions .slider-item-inner .logo {
    z-index: 2;
  }

  .main-home #qualityInclusions .slide-counter-inner {
    font-size: 1.35rem;
    margin: 2rem 1.5rem;
  }

  .main-home #qualityInclusions .sectionNav {
    margin-left: -1.25rem;
  }

  .main-home #qualityInclusions .sectionNav [class^="icon"] {
    font-size: 3rem;
  }

  .main-home #qualityInclusions .sectionNav .slide-counter {
    width: 14rem;
  }

  .main-home #qualityInclusions .sectionNav .slick-next,.main-home #qualityInclusions .sectionNav .slick-prev {
    height: 3rem;
    width: 1.5rem;
  }

  .main-home #qualityInclusions .text-box {
    height: 6rem;
    position: relative;
  }

  .main-home #qualityInclusions .text-box .included {
    display: none;
  }

  .main-home #qualityInclusions .text-box p {
    display: none;
  }

  .main-home #qualityInclusions .text-box h3 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-top: 0;
    font-size: 1.8rem;
    line-height: 1.95rem;
  }

  .main-home #includedList .sectionCopy .copy h2 {
    font-size: 4rem;
  }

  .main-home #includedList .sectionCopy .copy h3 {
    font-size: 1.2rem;
  }

  .main-home #includedList #list .disclaimer {
    left: 1.5rem;
    font-size: 0.9rem;
  }

  .main-home #includedList #list .copy {
    margin: 0;
    padding: 0;
  }

  .main-home #includedList #list p {
    font-size: 2.1rem;
    padding-bottom: 1.5rem;
    padding-top: 3.5rem;
    max-width: none;
    flex-basis: 100%;
    line-height: 1.1;
  }

  .main-home #includedList #list ul {
    margin: 0 0 0 0.4rem;
    flex-basis: 100%;
    padding: 0;
  }

  .main-home #includedList #list ul li {
    font-size: 1.2rem;
    line-height: 1.8rem;
    padding-left: 1.5rem;
    margin: 1rem 0;
  }

  .main-home #includedList #list ul li:before {
    top: 5px;
    font-size: 24px;
  }

  .main-home #includedList #list ul:last-of-type {
    margin-left: 0.4rem;
    margin-top: -1rem;
    margin-bottom: 3rem;
  }

  .main-home #newSandals .sectionCopy .copy h2 {
    font-size: 5rem;
  }

  .main-home #newSandals .sectionCopy {
    font-size: 4.7rem;
  }

  .main-home #newSandals .sectionCopy h3 {
    line-height: 1.3;
  }

  .main-home #newSandals .copy {
    margin-bottom: 0;
    padding-top: 8rem !important;
  }

  .main-home #newSandals .copy .blackButton {
    padding: 0 2.25rem !important;
  }

  .main-home #newSandals #newAtSandalsSlider {
    margin-left: 0;
  }

  .main-home #newSandals .slick-slide {
    margin: 0;
  }

  .main-home #newSandals .slick-slide > div {
    margin: 0 0.75rem;
  }

  .main-home #newSandals .slider-item {
    min-width: 27.15rem;
    width: 60vw !important;
    margin: 0;
  }

  .main-home #newSandals .slide-image {
    height: 100%;
  }

  .main-home #newSandals .slick-list {
    padding: 0 18% 0 0 !important;
  }

  .main-home #newSandals .slider-item-inner {
    height: 38vw;
    width: 100%;
    min-height: 17.5rem;
  }

  .main-home #newSandals .slider-item-inner img {
    width: 100%;
  }

  .main-home #newSandals .sectionNav {
    margin-left: -1.25rem;
  }

  .main-home #newSandals .sectionNav .slide-counter {
    margin-top: 2.25rem;
    width: 9rem;
  }

  .main-home #newSandals .sectionNav .slick-prev,.main-home #newSandals .sectionNav .slick-next {
    height: 2rem;
  }

  .main-home #newSandals .sectionNav .slide-counter-inner {
    font-size: 1.6rem;
    top: 0.3rem;
  }

  .main-home #newSandals .sectionNav [class^="icon"] {
    font-size: 2rem;
  }

  .main-home #newSandals .text-box {
    min-height: 29rem;
    height: auto;
  }

  .main-home #newSandals .text-box p {
    padding-top: 1.5rem;
    font-size: 1.05rem;
    line-height: 1.8rem;
    width: 95%;
    margin: 0 auto 1.2rem;
  }

  .main-home #newSandals .text-box h3 {
    font-size: 2.1rem;
    width: 95%;
    margin: -0.4rem auto 0;
  }

  .main-home #newSandals .text-box h4 {
    padding-top: 2rem;
    font-size: 1.2rem;
    line-height: 1;
    width: 90%;
    margin: 0 auto;
  }

  .main-home #newSandals .text-box a {
    font-size: 1.5rem;
    padding-bottom: 1rem;
  }

  .main-home #newSandals .text-box a i {
    top: 0.2rem;
    padding-left: 0.1rem;
  }

  .main-home .black-button #modify-xs {
    font-size: 1.4rem;
    line-height: 3rem;
    padding: 0 2.5rem;
    height: 3rem;
  }

  .main-home .mobileHide {
    display: none;
  }

  .main-home .included {
    font-size: 0.75rem;
    line-height: 1.6rem;
    height: 1.6rem;
    padding: 0 1.5rem;
  }

  .main-home #resortInformation .max-width > .container {
    position: relative;
    padding-top: 8rem;
  }

  .main-home #resortInformation #sandals-box {
    background: rgba(174, 190, 197, 0.5);
    transform: translate(-50%, -50%);
    top: 0;
  }

  .main-home #resortInformation #sandals-box .wrap {
    padding: 1.5rem 1.5rem 2rem;
  }

  .main-home #resortInformation #sandals-box i {
    font-size: 2.5rem;
  }

  .main-home #resortInformation #sandals-box .small {
    font-size: 0.85rem;
    line-height: 0.95rem;
  }

  .main-home #resortInformation #sandals-box .small span {
    font-size: 1rem;
    display: block;
  }

  .main-home #resortInformation #sandals-box .medium {
    font-size: 1.55rem;
    line-height: 1.45rem;
  }

  .main-home #resortInformation #sandals-box .big {
    font-size: 2.6rem;
    line-height: 2.2rem;
  }

  .main-home #resortInformation #sandals-box .play-button {
    height: 3rem;
    width: 3rem;
    bottom: -1.5rem;
    border-width: 0.2rem;
    outline: none;
  }

  .main-home #resortInformation #sandals-box .play-button i {
    font-size: 1.25rem;
    line-height: 2.6rem;
    left: 0.1rem;
  }

  .main-home #resortInformation #resortInfo-wrap {
    bottom: auto;
    position: relative;
    align-items: flex-start;
    margin: 0 auto;
    padding-bottom: 2rem;
    width: 27.7rem;
    left: 0;
    transform: none;
  }

  .main-home #resortInformation #resortInfo-wrap a {
    display: flex;
    align-items: center;
  }

  .main-home #resortInformation #resortInfo-wrap a div {
    padding-left: 1.5rem;
    text-align: left;
  }

  .main-home #resortInformation #resortInfo-wrap a h4 {
    font-size: 1.8rem;
  }

  .main-home #resortInformation #resortInfo-wrap a p {
    font-size: 1rem;
    margin: 0;
  }

  .main-home #resortInformation #resortInfo-wrap .square-module:hover {
    background: none;
    box-shadow: none;
  }

  .main-home #resortInformation #resortInfo-wrap .ic-planner,.main-home #resortInformation #resortInfo-wrap .ic-compare {
    font-size: 4rem !important;
  }

  .main-home #resortInformationImage {
    min-height: 26rem;
    height: 80vw;
  }

  .main-home #awards .sectionCopy .copy h2 {
    font-size: 3.5rem;
  }

  .main-home #awards .sectionCopy .copy p {
    padding: 0.5rem 0 0;
  }

  .main-home #awards .sectionCopy .blackButton {
    margin: 1rem auto 0;
  }

  .main-home #resortInfo-wrap {
    flex-flow: column;
    padding-right: 0;
    padding-left: 0;
  }

  .main-home #resortInfo-wrap .square-module i {
    font-size: 3.5rem;
  }

  .main-home #resortInfo-wrap .square-module h4 {
    font-size: 1.8rem;
  }

  .main-home .awardsBox {
    padding: 1rem 0.65rem 0.9rem;
  }

  .main-home #qqBlock {
    margin-top: 4rem;
  }

  .main-home #sandals {
    margin-top: 0;
    padding: 3.5rem 0 0;
  }

  .main-home #sandals > div {
    padding: 0;
    min-height: 14rem;
    height: 43vw;
  }

  .main-home #sandals img {
    width: 102%;
    max-width: none;
    margin-left: -1%;
    height: 100%;
    object-fit: cover;
  }

  .main-home #sandals .copy {
    margin-left: 1.5rem;
    padding: 0;
    top: 26%;
  }

  .main-home #sandals .button {
    width: 29rem;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
    top: 108%;
    background-color: #00b8f4;
  }

  .main-home #sandals .button .wrap {
    padding: 1rem 0;
    margin-left: 2.5rem;
    margin-right: 1.5rem;
  }

  .main-home #sandals .button .wrap > i {
    font-size: 3rem;
  }

  .main-home #sandals .button .stack {
    margin-right: 2rem;
  }

  .main-home #sandals .button .stack span {
    font-size: 1.15rem;
    line-height: 1.05rem;
  }

  .main-home #sandals .button .circle {
    height: 3rem;
    width: 3rem;
  }

  .main-home #sandals .button .circle i {
    color: #FFF;
    line-height: 3rem;
    font-size: 1.5rem;
  }

  .main-home #sandals h4 {
    font-size: 1.8rem;
    line-height: 1.9rem;
  }

  .main-home #sandals p {
    margin: 0.6rem 0 0;
    font-size: 1.05rem;
    line-height: 1.4rem;
  }

  .main-home #home-footer #middle-wrap {
    margin-top: 4rem;
    margin-bottom: 5rem;
  }

  .main-home #home-footer #middle-wrap #sandals-card {
    display: block;
  }

  .main-home #home-footer #middle-wrap .breaker {
    width: 4rem;
    height: 0.1rem;
    display: block;
    margin: 2rem auto;
  }

  .main-home #home-footer #middle-wrap #enter-sweeps {
    display: block;
    margin-top: 2rem;
  }

  .main-home #home-footer #middle-wrap #enter-sweeps label {
    font-size: 1.35rem;
    margin-bottom: 0.5rem;
    text-align: center;
    text-indent: -1.5rem;
  }

  .main-home #home-footer {
    padding-bottom: 6.25rem;
  }

  .main-home #home-footer .top i {
    font-size: 3.9rem;
  }

  .main-home #home-footer .top h2 {
    font-size: 3.75rem;
    line-height: 3.9rem;
  }

  .main-home #home-footer .top h2 sup {
    top: -1.2em;
  }

  .main-home #home-footer .top .from-pricing {
    font-size: 1.65rem;
    margin-top: 1rem;
  }

  .main-home #home-footer .top .from-pricing strong {
    font-size: 3.75rem;
  }

  .main-home #home-footer .top .from-pricing .stack {
    padding-left: 0.5rem;
  }

  .main-home #home-footer .top .from-pricing .stack .small {
    font-size: 1.4rem;
  }

  .main-home #home-footer .top a {
    font-size: 0.9rem;
    margin-top: 0;
  }

  .main-home #home-footer .top a i {
    font-size: 0.9rem;
  }

  .main-home #home-footer .book-online {
    font-size: 1.2rem;
  }

  .main-home #home-footer #sandals-card {
    margin-top: 3.5rem;
  }

  .main-home #home-footer #sandals-card img {
    height: auto;
    width: 23.6rem;
  }

  .main-home #home-footer #sandals-help-wrap .wrap {
    flex-flow: column;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .main-home #home-footer #sandals-help-wrap .module {
    margin: 1rem 0;
    flex-flow: row;
  }

  .main-home #home-footer #sandals-help-wrap .module i {
    font-size: 2rem;
  }

  .main-home #home-footer #sandals-help-wrap .module span {
    font-size: 1.05rem;
  }

  .main-home #home-footer #rates-button {
    width: 27.5rem;
    margin: 1rem auto 0.5rem;
    font-size: 2.4rem;
    line-height: 5.25rem;
    height: 5.25rem;
  }

  .main-home #home-footer #rates-button i {
    font-size: 2.5rem;
  }

  .main-home .slider-item-inner div.image {
    height: inherit;
  }

  .main-home .bottom-shadow {
    height: 6.5rem;
  }

  #sandalsIntroAwards .whats-included {
    display: block;
    margin: .5rem auto 4rem;
    max-width: 28rem;
  }

  #sandalsIntroAwards .whats-included #included-text {
    font-size: 1.93rem span;
    font-size-font-size: 1.29rem;
    font-size-line-height: 1;
  }

  #sandalsIntroAwards .whats-included #included-text-bt {
    font-size: 1.93rem span;
    font-size-font-size: 1.29rem;
    font-size-line-height: 1;
  }

  #sandalsIntroAwards .whats-included .btn-gray {
    height: 3rem;
    line-height: 3rem;
    font-size: 1.4rem;
    margin-top: 1.5rem;
    width: 19.5rem;
  }

  .btc-countdown-slide .countdown.new-countdown #countdown-slide_target {
    width: 80% !important;
  }

  .btc-countdown-slide .countdown.new-countdown #countdown-slide_target .count-digits {
    font-size: 3rem;
  }

  .main-home .chairman-widget {
    top: 1rem;
  }

  .reopening-back .background {
    background-size: cover;
    min-width: 100%;
    min-height: 100%;
    padding-bottom: 100%;
  }

  .reopening-back .background .container {
    position: relative;
    text-align: center;
  }

  .reopening-back .background .btc-reopen-home-title {
    margin-bottom: 0;
  }

  .reopening-back .background .title {
    font-size: 10.5rem;
    line-height: 8rem;
  }

  .reopening-back .background .title span {
    font-size: 9.5rem;
  }

  .reopening-back .background .subtitle {
    font-size: 2.5rem;
    letter-spacing: 0;
  }

  .reopening-back .background .view-reopening {
    margin: 30rem auto 1rem;
    top: 0;
  }

  .reopening-back .background .btc-grand-offer {
    position: absolute;
    margin: 5rem auto;
    padding: 0 0 100%;
    left: 0;
    right: 0;
    top: 60rem;
    text-align: center;
    background-image: url("https:://cdn.sandals.com/beaches/v12/images/home/btc-reopen/btc-sale-background-mobile.png");
  }

  .reopening-back .background .btc-grand-offer .btc-offer-title {
    padding-top: 4.2rem;
  }

  .reopening-back .background .btc-grand-offer .btc-offer-title .btc-reopen-view-details {
    margin-top: 5rem;
  }

  .reopening-back .background .protocols-info {
    position: absolute;
    margin: 5rem 3rem 0;
    left: 0;
    right: 0;
    top: 110rem;
    text-align: center;
  }
}

@media (max-width: 550px) {
  .btc-countdown-slide .countdown.new-countdown #countdown-slide_target .count-digits {
    font-size: 2.7rem;
  }
}

@media screen and (max-width: 430px) {
  .main-home #suitesSection #circle-wrap .circle {
    margin: 0 -3.5vw;
  }
}

@media (max-width: 425px) {
  .btc-countdown-slide .countdown.new-countdown {
    width: 65% !important;
  }

  .btc-countdown-slide .countdown.new-countdown #countdown-slide_target .count-btc-digits {
    font-size: 3rem;
  }

  .main-home .chairman-widget {
    width: 90%;
  }

  .main-home .chairman-widget .chairman-widget-info {
    padding-bottom: 5%;
  }

  .main-home .chairman-widget .chairman-widget-info .bm-subtitle {
    font-size: 0.8rem;
    letter-spacing: 0.2rem;
    margin-bottom: 0.5rem;
  }

  .main-home .chairman-widget .chairman-widget-info .bm-title {
    font-size: 2rem;
    margin-bottom: 1.5rem;
  }

  .main-home .chairman-widget .chairman-widget-info .bm-date {
    font-size: 1rem;
  }

  .main-home .chairman-widget .chairman-widget-info .bm-legacy {
    font-size: 1rem;
  }

  .newsletter .get-title {
    font-size: 3rem;
  }

  .newsletter .get-sub {
    font-size: 1.2rem;
  }

  .newsletter .form-submit-button {
    max-width: 40rem;
  }

  .newsletter .bottom-section .subscribe-checkbox {
    margin-left: -2rem;
  }

  .newsletter .bottom-section .checkbox-btn-wrapper {
    margin-left: -2rem;
  }

  .newsletter .bottom-section .checkbox-text {
    font-size: 1rem;
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

@keyframes reveal {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
